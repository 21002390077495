export const numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function formatNumbersEur(x) {
  let currentVal = x;
  if (!isNaN(currentVal)) {
    let testDecimal = testDecimals(currentVal);
    if (testDecimal.length > 1) {
      currentVal = currentVal.slice(0, -1);
    }

    return replaceCommas(currentVal);
  } else {
    return 0;
  }
}
function testDecimals(currentVal) {
  let count;
  currentVal.match(/\./g) === null
    ? (count = 0)
    : (count = currentVal.match(/\./g));
  return count;
}

function replaceCommas(yourNumber) {
  let components = yourNumber.toString().split(".");
  if (components.length === 1) components[0] = yourNumber;
  components[0] = components[0]
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (components.length === 2) components[1] = components[1].replace(/\D/g, "");
  return components.join(".");
}
export const checkDecimalsforSix = (value) => {
  let valueStr = value.toString().replace(/,/g, "");
  if (valueStr.includes(".")) {
    const valueSplit = valueStr.split(".");
    let decimalLength = valueSplit[1].length;
    if (decimalLength > 6) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const validateRange = (value, min, max) => {
  let valueStr = value.target.value.toString().replace(/,/g, "");
  let eventValue;

  if (valueStr.includes(".")) {
    const valueSplit = valueStr.split(".");
    let decimalLength = valueSplit[1].length;
    if (decimalLength > 0) {
      eventValue = parseFloat(valueStr).toFixed(
        decimalLength > 6 ? 6 : decimalLength
      );
    } else {
      return valueStr;
    }
  } else {
    eventValue = parseFloat(valueStr);
  }
  if (eventValue !== "") {
    if (eventValue > max) {
      return max;
    } else if (eventValue < 0) {
      return 0;
    } else if (eventValue > 0 && eventValue <= max) {
      return eventValue;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};


export const getEpochs =(stakeStartedAt)=>{

  const stakingStartDateTime = new Date(stakeStartedAt);
  const diff = new Date() - stakingStartDateTime;

  // Convert milliseconds to days
  const diffInDays = diff / (1000 * 60 * 60 * 24);
  // Divide the difference by the epoch duration (5 days) and round down to get the number of epochs
  const epochsPassed = Math.floor(diffInDays / 5);


  return epochsPassed;

}


