import styled from "styled-components";
import { Card } from "../../components/Cards/Card";

export const Label = styled.p`
  margin: -5px 0;
  word-break: break-word;
`;

export const DisclaimerLabel = styled.p`
  font-size: 14px;
  color: white;
`;

export const RewardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* padding:10px; */
`;
export const TotalHeader = styled.div`
  margin: -5px 0;
  font-size: 26px;
  color: ${(props) => props.theme.rewardHeader};
`;
export const AmountHeader = styled.div`
  margin: -5px 0;
  font-size: 32px;
  color: ${(props) => props.theme.rewardLabel};
`;
export const TotalHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;
export const LabelInfo = styled.p`
  margin: -5px 0;
  font-size: 14px;
  color: ${(props) => props.theme.addressInputPlaceholder};
`;

export const LabelInfoCard = styled.p`
  margin: -5px 0;
  font-size: 14px;
  color: ${(props) => props.theme.checkInfoCardTxt};
`;
export const DisplayCard = styled(Card)`
  width: 100%;
  align-items: start;
  background: #6B38FB;
  box-shadow: none;
  padding: 1.5rem;
  margin-bottom: 15px;
  @media (max-width: 1008px) {
    border-radius: 0;
    padding: 1.1rem 15px 15px 15px;
  }
`;

export const DisclaimerTitle = styled.h3`
  color: ${(props) => props.theme.buttonText};
`;

export const DisclaimerText = styled.p`
  color: ${(props) => props.theme.fontColor};
`;

export const DisclaimerLi = styled.li`
  color: ${(props) => props.theme.fontColor};
`;

export const DisclaimerSpan = styled.span`
  margin-left: 5px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`;

export const DisclaimerContent = styled.div`
  padding: 30px;
  border: none;
  border-radius: 8px;
  background-color: ${(props) => props.theme.ispoCardBg};
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
`;

export const UnderstoodButton = styled.button`
  width: 260px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(128, 133, 155, 0.2);
  border: none;
  color: white;
  background-color: #6b38fb;
  @media (max-width: 640px) {
    width: 100%;
  }
  &:hover {
    background-color: #6b38fb;
    color: white;
    opacity: 0.6;
  }
  &:focus {
    background-color: #6b38fb;
    color: white;
    box-shadow: none;
    opacity: 0.6;
  }
  &:active {
    transform: scale(0.99);
  }
`;

export const TokenReward = styled.div`
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 2px solid white;
  margin-bottom: 10px;
`