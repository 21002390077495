import React from "react";
import { DisplayCard, Label } from "./styles.element";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { LabelInfo } from "../DisplayRewards/styles.elements";

const CalculationInfo = ({ loadingInfo }) => {
  if (loadingInfo) {
    return (
      <DisplayCard borderRadius="8px">
        <Label>Fetching data ...</Label>
        <Box sx={{ width: "100%", mt: 1 }}>
          <LinearProgress
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#6B38FB",
              },
            }}
          />
        </Box>
      </DisplayCard>
    );
  } else {
    return (
      <DisplayCard borderRadius="8px">
        <LabelInfo>
          Note: LSPO 1 has ended and the LSPO 1 rewards calculator also shows
          the total rewards with bonus. The snapshot has been submitted to
          DripDropz.
        </LabelInfo>
      </DisplayCard>
    );
  }
};

export default CalculationInfo;
