import styled from "styled-components";

export const TabBarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    background-color: ${(prop) => prop.theme.body};
    padding: 6px;
    border-radius: 20px;
`

export const Tab = styled.div`
    background-color: ${(prop) => prop.selected ?  prop.theme.ispoCardBg : 'transparent'};
    padding: 4px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
`