import styled from "styled-components";
import { Card } from "../../../components/Cards/Card";

export const Title = styled.p`
  color: ${(props) => props.theme.addressInputPlaceholder};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

export const TokenLabel = styled.p`
  margin: -5px 0;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.fontColor};
`;

export const Label = styled.p`
  margin: -5px 0;
  word-break: break-word;
  font-size: 14px;
  color: ${(props) => props.theme.fontColor};
`;

export const DisclaimerLabel = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.fontColor};
`;

export const RewardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* padding:10px; */
`;
export const TotalHeader = styled.div`
  margin: -5px 0;
  font-size: 26px;
  color: ${(props) => props.theme.rewardHeader};
`;
export const AmountHeader = styled.div`
  margin: -5px 0;
  font-size: 32px;
  color: ${(props) => props.theme.rewardLabel};
`;
export const TotalHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;
export const LabelInfo = styled.p`
  margin: -5px 0;
  font-size: 14px;
  color: ${(props) => props.theme.addressInputPlaceholder};
`;

export const LabelInfoCard = styled.p`
  margin: -5px 0;
  font-size: 14px;
  color: ${(props) => props.theme.checkInfoCardTxt};
`;
export const DisplayCard = styled(Card)`
  width: 100%;
  align-items: start;
  row-gap: 1rem;
  background: ${(props) => props.theme.ispoCardBg};
  /* margin-bottom: 20px; */
  padding: 1.5rem 30px 1.5rem 30px;
  box-shadow: none;
  border-radius: 8px !important;
  margin-bottom: 15px;
  @media (max-width: 1439px) {
    padding: 1.5rem 15px 1.5rem 15px;
  }
  @media (max-width: 1008px) {
    padding: 1.2rem 1.5rem 1.5rem 1.5rem;
  }
  @media (max-width: 640px) {
    padding: 1.2rem 15px 1.5rem 15px;
  }
`;

export const DisclaimerTitle = styled.h3`
  color: ${(props) => props.theme.buttonText};
`;

export const DisclaimerText = styled.p`
  color: ${(props) => props.theme.fontColor};
`;

export const DisclaimerLi = styled.li`
  color: ${(props) => props.theme.fontColor};
`;

export const DisclaimerSpan = styled.span`
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

export const DisclaimerContent = styled.div`
  padding: 30px;
  border: none;
  border-radius: 8px;
  background-color: ${(props) => props.theme.ispoCardBg};
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
`;

export const UnderstoodButton = styled.button`
  width: 260px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(128, 133, 155, 0.2);
  border: none;
  color: white;
  background-color: #6b38fb;
  @media (max-width: 640px) {
    width: 100%;
  }
  &:hover {
    background-color: #6b38fb;
    color: white;
    opacity: 0.6;
  }
  &:focus {
    background-color: #6b38fb;
    color: white;
    box-shadow: none;
    opacity: 0.6;
  }
  &:active {
    transform: scale(0.99);
  }
`;

export const TokenReward = styled.div`
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 2px solid white;
  margin-bottom: 10px;
`;

export const LabelCard = styled.div`
  padding: 15px;
  width: 100%;
  border-bottom: ${(props) =>
    `0.5px solid ${props.theme.addressInputPlaceholder}`};
`;

export const DetailCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  row-gap: 0.8rem;
`;

export const BoldSpan = styled.span`
  font-weight: 600;
  margin-left: 5px;
`;

export const InfoCard = styled(Card)`
  background-color: #f6f8fc;
  align-items: start;
  width: 100%;
  background: ${(props) => props.theme.checkInfoCardBg};
  box-shadow: none;
  padding: 0px;
  @media (max-width: 640px) {
    border-radius: 8px !important;
  }
`;
