import styled from "styled-components";
import { Card } from "../../components/Cards/Card";

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const Title = styled.p`
  color: ${(props) => props.theme.addressInputPlaceholder};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

export const TextButton = styled.button`
  background: transparent;
  border: none;
  color: ${(props) => props.theme.buttonText};
  margin-top: 4px;
  &:hover {
    color: #6b37fa;
  }
`;

export const AmountView = styled.p`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.fontColor};
`;

export const StakeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 0.1rem;
`;

export const DelegateWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 0.1rem;
`;
export const GlobalCard = styled(Card)`
  width: 100%;
  align-items: start;
  row-gap: 1rem;
  background: ${(props) => props.theme.ispoCardBg};
  /* margin-bottom: 20px; */
  padding: 1.5rem 30px 1.5rem 30px;
  box-shadow: none;
  border-radius: 8px !important;
  @media (max-width: 1439px) {
    padding: 1.5rem 15px 1.5rem 15px;
  }
  @media (max-width: 1008px) {
    padding: 1.2rem 1.5rem 1.5rem 1.5rem;
  }
  @media (max-width: 640px) {
    padding: 1.2rem 15px 1.5rem 15px;
  }
`;
export const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 0.7rem;
`;
