import storage from "../assets/menuIcons/storage.svg";
import storageDark from "../assets/menuIconsDark/storage.svg";
import howItWorks from "../assets/menuIcons/how-it-works.svg";
import howItWorksDark from "../assets/menuIconsDark/how-it-works.svg";
import provideStorage from "../assets/menuIcons/provide-storage.svg";
import provideStorageDark from "../assets/menuIconsDark/provide-storage.svg";
import documentation from "../assets/menuIcons/whitepaper.svg";
import documentationDark from "../assets/menuIconsDark/whitepaper.svg";
import story from "../assets/menuIcons/our-story.svg";
import storyDark from "../assets/menuIconsDark/our-story.svg";
import team from "../assets/menuIcons/our-team.svg";
import teamDark from "../assets/menuIconsDark/our-team.svg";
import partner from "../assets/menuIcons/our-partner.svg";
import partnerDark from "../assets/menuIconsDark/our-partner.svg";
import contactUs from "../assets/menuIcons/contact.svg";
import contactUsDark from "../assets/menuIconsDark/contact.svg";
import blog from "../assets/menuIcons/blog.svg";
import blogDark from "../assets/menuIconsDark/blog.svg";
import join from "../assets/menuIcons/become-a-member.svg";
import joinDark from "../assets/menuIconsDark/become-a-member.svg";

export const SearchResult = [
  {
    name: "Storage",
    desc: "Iagons core storage solution",
    searchIcon: storage,
    searchIconDark: storageDark,
    path: "https://iagon.com/storage",
  },
  {
    name: "How it works",
    desc: "Shared Storage Economy",
    searchIcon: howItWorks,
    searchIconDark: howItWorksDark,
    path: "https://iagon.com/earn#how-it-works",
  },
  {
    name: "Provide storage",
    desc: "Contribute and earn from it!",
    searchIcon: provideStorage,
    searchIconDark: provideStorageDark,
    path: "https://iagon.com/earn#iagon-ambition",
  },
  {
    name: "Documentation",
    desc: "Our Documentation",
    searchIcon: documentation,
    searchIconDark: documentationDark,
    path: "https://docs.iagon.com/",
  },
  {
    name: "Our story",
    desc: "What is our mission?",
    searchIcon: story,
    searchIconDark: storyDark,
    path: "https://iagon.com/about-us#story",
  },
  {
    name: "Our team",
    desc: "Check who we are",
    searchIcon: team,
    searchIconDark: teamDark,
    path: "https://iagon.com/about-us/#team",
  },
  {
    name: "Our partners",
    desc: "Who we work with",
    searchIcon: partner,
    searchIconDark: partnerDark,
    path: "https://iagon.com/careers/#partners",
  },
  {
    name: "Contact us!",
    desc: "Check partnership opportunities.",
    searchIcon: contactUs,
    searchIconDark: contactUsDark,
    path: "https://share-eu1.hsforms.com/1E3-0_BorTPOaWjyXLtU2PAf0lrm",
  },
  {
    name: "Community",
    desc: "Join our community",
    searchIcon: team,
    searchIconDark: teamDark,
    path: "https://linktr.ee/iagon",
  },
  {
    name: "Join our team",
    desc: "Check job offers!",
    searchIcon: join,
    searchIconDark: joinDark,
    path: "https://iagon.com/careers",
  },
  {
    name: "Blog",
    desc: "Check our blog!",
    searchIcon: blog,
    searchIconDark: blogDark,
    path: "https://blog.iagon.com/",
  },
];

export default SearchResult;
