import React from "react";
import {
  ChooseLspoCard,
  Title,
  ChooseButton,
  ChooseLspoContainer,
  PageHeading,
  LabelInfo,
  DescriptionCard,
} from "./styles.elements";

const ChooseLspo = () => {
  return (
    <ChooseLspoContainer>
      <PageHeading>Choose Liquidity Stake Pool Offering</PageHeading>
      <ChooseLspoCard>
        <ChooseButton href="/lspo2">
          <Title>LSPO 2</Title>
          <LabelInfo>Epoch 483 to Epoch 555</LabelInfo>
        </ChooseButton>
        <ChooseButton href="/lspo1">
          <Title>LSPO 1</Title>
          <LabelInfo>Epoch 407 to Epoch 482</LabelInfo>
        </ChooseButton>
      </ChooseLspoCard>
      <DescriptionCard>
        <Title>LSPO 1 Rewards</Title>
        <LabelInfo>
          Over the course of Phase 1, a staggering 30 million IAG was awarded to
          participants of the program. These rewards will be distributed over a
          12-month vesting period, with the first period commencing mid May,
          2024.
          <br />
          <br />
          Rewards will be claimable each month through the DripDropz platform
          here -{" "}
          <a
            href="https://dripdropz.io/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://dripdropz.io/
          </a>
        </LabelInfo>
        <br />
        <Title>LSPO 2 Introduction</Title>
        <LabelInfo>
          Given the success of the LSPO, the Iagon team has decided to extend
          the program in a modified format.
          <br />
          <br />
          As of May 1st, 2024, Phase II of the program will commence for an
          additional 1 year period. Similarly to Phase I, participants will be
          incentivized to provide liquidity for the IAG/ADA token pair and
          delegate their ADA to the Iagon staking pools, however, the rewards
          for the second phase will see the introduction of tokens from various
          partner projects.
          <br />
          <br />
          The details can be found here: Link -{" "}
          <a
            href="https://docs.iagon.com/overview/events/lspo#phase-ii"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline" }}
          >
            https://docs.iagon.com/overview/events/lspo#phase-ii
          </a>
        </LabelInfo>
      </DescriptionCard>
    </ChooseLspoContainer>
  );
};

export default ChooseLspo;
