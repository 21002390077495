import styled from "styled-components";

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 42px;
  border: 1px solid ${(props) => props.theme.addressInputBorder};
  border-radius: 10px;
  padding: 0rem 0.7rem;

  &:focus-within {
    outline: none;
    border: 1px solid ${props=>props.value!== ""?props.theme.inputFocusedFilled:props.theme.inputFocusedEmpty};
  }
  &:hover {
    outline: none;
    border: 1px solid ${props=>props.value!== ""?props.theme.inputFilledHovered:props.theme.inputEmptyHovered};

  }
  ${(props) =>
    props.value !== ""
      ? `border : 1px solid ${(props) => props.theme.inputFilled}`
      : null}
`;
export const Button = styled.button`
  border: none;
  font-family: inherit;
  font-size: 0.8rem;
  /* padding-right: 1rem; */
  color: #6b38fb;
  visibility: hidden;
`;
export const Input = styled.input`
  flex-grow: 2;
  border: none;
  font-size: 1rem;
  width: 100%;
  color: ${(props) => props.theme.addressInput};
  ::placeholder {
    color: ${(props) => props.theme.addressInputPlaceholder};
  }

  &:focus-within {
    outline: none;
  }
  &:focus ~ ${Button} {
    visibility: visible !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  background-color: transparent;
  margin: 0.3rem 0.3rem 0.3rem 0rem;
  border-radius: 1rem;
`;
