import styled from "styled-components";
import { Card } from "../../components/Cards/Card";

export const RewardHistoryCard = styled(Card)`
  align-items: start;
  width: 100%;
  background: ${(props) => props.theme.ispoCardBg};
  padding: 1.5rem 30px 1.5rem 30px;
  box-shadow: none;
  border-radius: 8px !important;
  margin: auto;
  margin-top: 15px;
  max-width: 1015px;
  margin-bottom: 25px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
`;
export const TextButton = styled.button`
  background: transparent;
  border: none;
  color: ${(props) => props.theme.buttonText};
  margin-top: 4px;
  &:hover {
    color: #6b37fa;
  }
`;

export const LabelInfo = styled.p`
  margin: -5px 0;
  font-size: 14px;
  color: ${(props) => props.theme.addressInputPlaceholder};
  @media (max-width: 775px) {
    display: ${(props) => (props.hideOnMobile === 1 ? "none" : "")};
  }
`;

export const Value = styled.p`
  font-size: 14px;
  margin-left: 5px;
  margin-bottom: 0px;
  @media (max-width: 775px) {
    display: ${(props) => (props.hideOnMobile === 1 ? "none" : "")};
  }
`;
export const ValueExp = styled.p`
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 8px;
  @media (max-width: 775px) {
    display: ${(props) => (props.hideOnMobile === 1 ? "none" : "")};
  }
`;

export const Header = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr 1fr 1fr 1fr 0.5fr;
  margin-bottom: 20px;
  @media (max-width: 775px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
  }
`;

export const Reward = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 5px;
`;

export const RewardHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr 1fr 1fr 1fr 0.5fr;
  padding: 5px;
  border-bottom: 1px solid #dbdbdc;
  background-color: ${(props) =>
    props.expanded === 1 ? props.theme.rewardHead : ""};
  @media (max-width: 775px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
  }
`;

export const OnlyMobile = styled.div`
  display: none;
  @media (max-width: 775px) {
    display: block;
  }
`

export const ExpandMore = styled.div`
  display: flex;
  justify-content: end;
`;

export const ExpandedDetail = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 20px;
  background-color: ${(props) => props.theme.rewardExpanded};
  @media (max-width: 775px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 15px;
  }
`;
