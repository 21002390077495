import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";

export const Name = styled.p`
margin:0;
padding:0;
color: ${(props) => props.theme.buttonText};
margin-left:10px ;
`;

export const WalletItem = styled(Button)`
display:flex ;
flex-direction:row;
align-items:center ;
margin-bottom:10px;
height:60px;

&:hover {
border: 1px solid ${(props) => props.theme.text};
/* background-color:${(props)=>props.theme.hover} ; */
  }
`;

export const CancelButton = styled(Button)`
  color: #6b38fb;
  background-color: #e2e6ff;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(128, 133, 155, 0.2);
  width: 92px;
  border: none;
  outline: none;
  height: 40px;
  font-weight: bold;

  &:hover {
    background-color: #e2e6ff;
    color: #6b38fb;
    opacity: 0.6;
  }
  &:focus {
    background-color: #e2e6ff;
    color: #6b38fb;
    box-shadow: none;
    opacity: 0.6;
  }
  &:active {
    transform: scale(0.99);
  }
`;
export const EditButton = styled(Button)`
  width: 138px;
  height: 40px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(128, 133, 155, 0.2);
  border: none;
  color: white;
  background-color: #6b38fb;
  &:hover {
    background-color: #6b38fb;
    color: white;
    opacity: 0.6;
  }
  &:focus {
    background-color: #6b38fb;
    color: white;
    box-shadow: none;
    opacity: 0.6;
  }
  &:active {
    transform: scale(0.99);
  }
`;
export const ModalBody = styled.div`
  border: none;
  border-radius: 5px;
  background-color: ${(props) => props.theme.ispoCardBg};
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  padding: 12px 1.5rem;
`;
export const ModalInfoBody = styled(Modal.Body)`
  background: ${(props) => props.theme.ispoCardBg};
  font-size: 16px;
  padding: 0;
  margin-top: 8px;
`;

export const ModalHeader = styled(Modal.Header)`
  display: flex;
  align-items: flex-start;
  border-bottom: none;
  background: ${(props) => props.theme.ispoCardBg};
  padding: 0;
`;

export const ModalTitle = styled(Modal.Title)`
  color: ${(props) => props.theme.buttonText};
  font-weight: normal;
  font-size: 26px;
  padding: 0;
  margin-top: 0;
`;

export const ModalFooter = styled(Modal.Footer)`
  background: ${(props) => props.theme.ispoCardBg};
  border-top: none;
  margin-top: 30px;
  column-gap: 20px;
  padding: 0;
`;
export const CrossIcon = styled.img`
  margin-top: 12px;
  cursor: pointer;
`;
