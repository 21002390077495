import React from "react";
import { Modal } from "react-bootstrap";
import { useTheme } from "styled-components";

import {
  CancelButton,
  CrossIcon,
  Name,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalInfoBody,
  ModalTitle,
  WalletItem,
} from "./styles.elements";

import Cross from "../../assets/icons/cross.svg";
import xSvg from "../../assets/icons/x.svg";
import etrnl_wallet from "../../assets/Logos/etrnl.png";
import nami_wallet from "../../assets/Logos/nami.png";
import flint_wallet from "../../assets/Logos/flint.png";
import typhon_wallet from "../../assets/Logos/typhon.svg";
import gero_wallet from "../../assets/Logos/gerowallet.png";
import yoroi_wallet from "../../assets/Logos/yoroi.png";

import "./modal.css";

function ConnectModal(props) {
  const theme = useTheme();
  const { onReject, onHide, setWalletName } = props;

  const handleSelect = (wallet) => {
    setWalletName(wallet);
  };

  const supported_wallets = [
    {
      title: "Nami Wallet",
      src: nami_wallet,
      name: "nami",
    },
    {
      title: "Etrnl Wallet",
      src: etrnl_wallet,
      name: "eternl",
    },
    {
      title: "Flint Wallet",
      src: flint_wallet,
      name: "flint",
    },
    {
      title: "Typhon Wallet",
      src: typhon_wallet,
      name: "typhon",
    },
    {
      title: "Yoroi Wallet",
      src: yoroi_wallet,
      name: "yoroi",
    },
    {
      title: "Gero Wallet",
      src: gero_wallet,
      name: "gerowallet",
    }
  ];

  return (
    <>
      <Modal
        {...props}
        textsize="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ border: "none" }}
      >
        <ModalBody>
          <ModalHeader>
            <ModalTitle id="contained-modal-title-vcenter">
              Connect your wallet{" "}
            </ModalTitle>
            <CrossIcon
              src={theme.type === "light" ? xSvg : Cross}
              alt={`modal cross ${theme.type} icon`}
              width="16px"
              height="16px"
              onClick={() => onReject(false)}
            />
          </ModalHeader>

          <ModalInfoBody>
            Please choose the wallet you want to connect{" "}
          </ModalInfoBody>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            {supported_wallets.map((item, index) => {
              return (
                <WalletItem
                  key={index}
                  variant="outline"
                  onClick={() => {
                    handleSelect(item.name);
                    onHide();
                  }}
                  w="100%"
                >
                  <img width={"45px"} alt={item.name} src={item.src} />
                  <Name>{item.title}</Name>
                </WalletItem>
              );
            })}
          </div>
          <ModalFooter>
            <CancelButton onClick={onReject}>Cancel</CancelButton>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </>
  );
}

export { ConnectModal };
