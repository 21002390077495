let totalEpochs = 73; //5 days per epock, 73 weeks in a year, can be changed

let baseRewardDividend = 1.5; //to make sure there total rewards doesn't exceed allocated total rewards

//Constants for exponential formula
let betaValue = 0.03; //growth coefficient on eulers number to be increased each new epoch

//Constants for damping formula
let gammaValue = 6;

//Constants for effect of ada delegation
let epsilonValue = 0.3; //for more effect, lower value gives more effect
let delta_value = 100;

//Constants for effect of iag_ada staking
let epsilon1Value = 0.1; //for less effect
let delta1Value = 100;

//alpha value
let alphaValue = 0.25;

var getRewards = function (
  n,
  lastEpochReward,
  proportionAda,
  proportionIagAda,
  totalReward,
  baseReward
) {
  var rexp = getRexp(n);
  var rdamp = getRdamp(lastEpochReward, totalReward);
  var rstake = getRstake(proportionIagAda);
  var rdelegate = getRdelegate(proportionAda);
  var rfactors = rexp * rdamp * rdelegate * rstake;
  var rewardsTotal = baseReward * Math.pow(rfactors, alphaValue);
  return rewardsTotal;
};

var getRexp = function (n) {
  var rexp = Math.pow(Math.E, n * betaValue);
  return rexp;
};

var getRdamp = function (lastEpochReward, totalReward) {
  var rdamp = 1 - Math.pow(lastEpochReward / totalReward, gammaValue);
  return rdamp;
};
var getRdelegate = function (proportionAda) {
  var rdelegate = 1 + Math.pow(proportionAda / delta_value, epsilonValue);
  return rdelegate;
};
function getRstake(proportionIagAda) {
  var rstake = 1 + Math.pow(proportionIagAda / delta1Value, epsilon1Value);
  return rstake;
}

export const calculateSaturnReward = (
  totalStaked,
  totalDelegated,
  userStaked,
  userDelegated,
  saturnPrice,
  adaPrice
) => {
  const calculatedRewards = {};
  var rewards = [];
  var sumRewards = [];
  var rewardProportions = [];

  const totalReward = 1000000;
  const baseReward = totalReward / (totalEpochs * baseRewardDividend);

  for (var i = 1; i <= totalEpochs; i++) {
    var proportionDelegated = userDelegated / totalDelegated;
    var proportionStaked = userStaked / totalStaked;

    var totalRewardProportion =
      (userDelegated * adaPrice + userStaked) /
      (totalStaked + totalDelegated * adaPrice);

    rewardProportions[i] = totalRewardProportion;
    if (i === 1) {
      rewards[i] = getRewards(
        i,
        0,
        proportionDelegated,
        proportionStaked,
        totalReward,
        baseReward
      );
      sumRewards[i] = rewards[i];
    } else {
      rewards[i] = getRewards(
        i,
        sumRewards[i - 1],
        proportionDelegated,
        proportionStaked,
        totalReward,
        baseReward
      );
      sumRewards[i] = sumRewards[i - 1] + rewards[i];
    }
    var saturnReward = sumRewards[i] * totalRewardProportion;
    if (i === totalEpochs && saturnReward > totalReward) {
      saturnReward = totalReward;
    }
    const dollarReward = saturnReward * saturnPrice;
    const apy =
      (((dollarReward / (userStaked + userDelegated * adaPrice)) *
        totalEpochs) /
        i) *
      100;
    calculatedRewards[i] = {
      saturnReward,
      dollarReward,
      apy,
    };
  }
  return calculatedRewards;
};
