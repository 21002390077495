import React, { useState, useEffect, useRef } from "react";
import { Link } from "../Link";
import { useTheme } from "styled-components";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import DropdownElement from "./dropdownElement";
import data from "../../data/searchResults";
import useOnClickOutside from "./useOnClickOutside";

export default function SearchMenu({ expand, handleSearch }) {
  const theme = useTheme();
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = ({ target: { value } }) => {
    setInputValue(value);
    setShowSearchResult(value.trim() !== "");
  };

  const filteredItems = data.filter((el) =>
    el.name.toLocaleLowerCase().includes(inputValue?.toLocaleLowerCase())
  );

  const hideSearch = () => {
    if (expand) {
      setTimeout(() => {
        handleSearch();
      }, 300);
    }
  };

  useEffect(() => {
    if (!expand) {
      setInputValue("");
      setShowSearchResult(false);
    }
  }, [expand]);

  const ref = useRef(null);

  useOnClickOutside(ref, hideSearch);

  return (
    <div
      ref={ref}
      className="searchInput relative lg:top-0 ml-12 lg:ml-5 right-0"
    >
      <div
        className={`absolute right-0
        ${expand && "right-6 sm:right-0"}
		${showSearchResult ? "rounded-t-lg" : "rounded-lg"}
		${theme.type === "dark" ? "bg-[#100F18] text-[#FFFFFF]" : "bg-white"}
		flex relative overflow-hidden`}
      >
        <button
          aria-label="search-button"
          className="p-2 bg-none flex items-center justify-center rounded-md cursor-pointer"
          onClick={handleSearch}
        >
          <MagnifyingGlassIcon className="w-6 h-6" />
        </button>
        <input
          className={`h-10 outline-none rounded-lg bg-transparent ease-in-out duration-300 ${
            expand ? "w-48 px-3" : "delay-300 w-0 h-0 p-0"
          }`}
          placeholder="Search"
          value={inputValue}
          onChange={handleInputChange}
        />
        <button
          onClick={hideSearch}
          className={`absolute right-4 top-2.5   ${
            expand
              ? "rotate-0  h-5 w-5 transition duration-300 "
              : "h-0 w-0 rotate-180 transition duration-300"
          }`}
        >
          <XMarkIcon />
        </button>
      </div>
      <div
        className={`searchItem top-full absolute w-full ${
          theme.type === "dark" ? "bg-[#100F18]" : "bg-white"
        }`}
      >
        <ul
          className={`  ease-in-out duration-500 overflow-hidden ${
            showSearchResult ? "p-2 max-h-fit" : "p-0 h-0"
          } `}
        >
          {filteredItems.length > 0 ? (
            filteredItems.map((el) => (
              <li
                key={el.name}
                className={`ease-in-out duration-500 overflow-hidden ${
                  showSearchResult ? " h-fit scale-y-100" : "h-0 scale-y-0 "
                } `}
              >
                <Link
                  href={el.path}
                  target=""
                  onClick={handleSearch}
                  className={` ${
                    showSearchResult ? "scale-o" : " scale-100"
                  } duration-300 rounded-lg ${
                    theme.type === "dark"
                      ? "hover:bg-gray-500/50"
                      : "hover:bg-gray-100"
                  }`}
                >
                  <DropdownElement
                    title={el.name}
                    subTitle=""
                    icon={el.searchIcon}
                    iconDark={el.searchIconDark}
                  />
                </Link>
              </li>
            ))
          ) : (
            <div className="text-center py-2">No result found</div>
          )}
        </ul>
      </div>
    </div>
  );
}
