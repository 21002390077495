import styled from "styled-components";
export const ToastTitle = styled.span`
  font-size: 16px;
  padding: 0;
  margin: 0;
  margin-left: 5px;
  color: ${(props) => props.theme.fontColor};
`;

export const ToastDesc = styled.p`
  font-size: 12px;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  color: ${(props) => props.theme.fontColor};
`;

export const CrossIcon = styled.img``;
export const ToastWrap = styled.div`
  box-shadow: 0 0 40px 0 rgba(128, 133, 155, 0.4);
  background-color: ${(props) => props.theme.ispoCardBg};
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  border-radius: 8px;
`;
export const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 310px;
`;
export const SuccessIconText = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`;
