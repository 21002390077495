import {
  RESET_GLOBAL_SETTINGS,
  SET_GLOBAL_DELEGATED,
  SET_GLOBAL_REWARDED,
  SET_GLOBAL_SETTINGS,
  SET_WALLET,
} from "./actions";
import { ReactSession } from "react-client-session";

const initialState = {
  totalRewarded: Number(ReactSession.get("totalRewarded") || 30000000),
  totalStaked: Number(ReactSession.get("totalStaked") || 300000),
  totalDelegated: Number(ReactSession.get("totalDelegated") || 1160000),
  totalIagUsdm: Number(ReactSession.get("totalIagUsdm") || 300000),
  wallet: undefined,
};

const calculatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WALLET:
      return {
        ...state,
        wallet: action.payload.wallet,
      };
    case SET_GLOBAL_SETTINGS:
      return {
        ...state,
        totalRewarded: action.payload.totalRewarded,
        totalStaked: action.payload.totalStaked,
        totalDelegated: action.payload.totalDelegated,
        totalIagUsdm: action.payload.totalIagUsdm
      };
    case RESET_GLOBAL_SETTINGS:
      return {
        ...state,
        totalRewarded: 5,
        totalStaked: 10,
        totalDelegated: 10,
      };
    case SET_GLOBAL_DELEGATED:
      return {
        ...state,
        totalDelegated: action.payload.totalDelegated,
      };
    case SET_GLOBAL_REWARDED:
      return {
        ...state,
        totalDelegated: action.payload.totalRewarded,
      };

    default:
      return state;
  }
};

export { calculatorReducer };
