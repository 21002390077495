import React, { useState, useEffect, useRef } from "react";
import {
  StyledRow,
  StakeGlobalColumn,
  InputsContainer,
  PageHeading,
  DisplayColumn,
  DisplayContainer,
  DisplayRewardWrapperMobile,
  ShadowIndicator,
} from "./styles.elements";
import RewardHistory from "../../Containers/RewardHistory";
import RewardSummary from "../../Containers/Reward Summary";
import CalculationInfo from "../../Containers/CalculationInfo";
import {
  TextButton,
  BackImg,
} from "../../Containers/StakeInput/styles.elements";
import arrowBack from "../../assets/icons/arrow-left.svg";
import { StakeInput } from "../../Containers";

const Lspo1 = () => {
  const useDetectSticky = (ref, observerSettings = { threshold: [1] }) => {
    const [isSticky, setIsSticky] = useState(false);
    const newRef = useRef();
    ref ||= newRef;

    // mount
    useEffect(() => {
      const cachedRef = ref.current,
        observer = new IntersectionObserver(([e]) => {
          setIsSticky(e.isIntersecting);
        }, observerSettings);

      observer.observe(cachedRef);

      // unmount
      return () => {
        observer.unobserve(cachedRef);
      };
    }, []);

    return [isSticky, ref, setIsSticky];
  };
  const [isSticky, ref] = useDetectSticky();
  const [address, setAddress] = useState("");
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [summary, setSummary] = useState();

  return (
    <div>
      <PageHeading>LSPO 1 Reward Calculator</PageHeading>
      <StyledRow style={{ padding: "0", columnGap: "24px" }}>
        <StakeGlobalColumn lg={6} md={12}>
          <TextButton
            onClick={() => (window.location.href = "/")}
            style={{ margin: "10px" }}
          >
            <BackImg src={arrowBack} /> Go Back
          </TextButton>
          <InputsContainer>
            <StakeInput
              address={address}
              setAddress={setAddress}
              loadingInfo={loadingInfo}
              setLoadingInfo={setLoadingInfo}
              setRewards={setRewards}
              setSummary={setSummary}
            />
            <div ref={ref} />
          </InputsContainer>
        </StakeGlobalColumn>
        <DisplayColumn lg={6} md={12} style={{ padding: "0" }}>
          <TextButton style={{ margin: "10px", opacity: 0, cursor: "none" }}>
            <BackImg src={arrowBack} /> Go Back
          </TextButton>
          <DisplayContainer className="appear_animation-2s transform-center">
            {summary && address !== "" && !loadingInfo ? (
              <RewardSummary summary={summary} />
            ) : (
              <CalculationInfo loadingInfo={loadingInfo} />
            )}
          </DisplayContainer>
        </DisplayColumn>
      </StyledRow>
      {rewards.length > 0 && !loadingInfo && (
        <RewardHistory
          className="appear_animation-2s transform-center"
          rewards={rewards}
        />
      )}
      <ShadowIndicator ref={ref} />

      <DisplayRewardWrapperMobile
        className="appear_animation-2s transform-center"
        issticky={!isSticky ? 1 : 0}
      >
        {summary && address !== "" && !loadingInfo && (
          <RewardSummary summary={summary} />
        )}
      </DisplayRewardWrapperMobile>
    </div>
  );
};

export default Lspo1;
