import React, { useState, useEffect, useRef } from "react";
import {
  PageHeading,
  StyledRow,
  StakeGlobalColumn,
  DisplayColumn,
  DisplayContainer,
  GlobalSettingsWrapperMobile,
  DisplayRewardWrapper,
  Title,
  NotesInfo,
  DisplayRewardWrapperMobile,
  NotesWrapper,
  NotesWrapperMobile,
  ShadowIndicator,
} from "./styles.elements";
import { GlobalSettings } from "../../Containers";
import { useAPIContext } from "../../contexts/calculatorContext";
import RangeBar from "../../components/RangeBar";
import { formatNumbersEur, validateRange } from "../../helper";
import {
  InputRangeWrapper,
  LabelInputWrapper,
  RangeGuide,
  StakeCard,
  InfoCard,
  InputMainWrapper,
  NoteCard,
  TextButton,
  BackImg,
} from "../../Containers/StakeInput/styles.elements";
import Input from "../../components/Input";
import {
  LabelInfo,
  LabelInfoCard,
} from "../../Containers/DisplayRewards/styles.elements";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import { calculateRewards } from "./calculation";
import Lspo2Rewards from "../../Containers/DisplayRewards/lspo2";
import TwoThumbsDraggableTrack from "../../components/RangeBar/twoThumb";
import arrowBack from "../../assets/icons/arrow-left.svg";

const useDetectSticky = (ref, observerSettings = { threshold: [1] }) => {
  const [isSticky, setIsSticky] = useState(false);
  const newRef = useRef();
  ref ||= newRef;

  // mount
  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(([e]) => {
        setIsSticky(e.isIntersecting);
      }, observerSettings);

    observer.observe(cachedRef);

    // unmount
    return () => {
      observer.unobserve(cachedRef);
    };
  }, []);

  return [isSticky, ref, setIsSticky];
};

const Lspo2 = () => {
  const theme = useTheme();
  const {
    adaPricePerEpoch,
    iagPricePerEpoch,
    nvlPrice,
    totalDelegated,
    tvl,
    tvlIagUsdm,
  } = useAPIContext();
  const saturnPrice = 0.15;
  const iagPrice = iagPricePerEpoch[iagPricePerEpoch.length - 1].price;
  const adaPrice = adaPricePerEpoch[adaPricePerEpoch.length - 1].price;
  const [globalSettingsEditMode, setGlobalSettingsEditMode] = useState(false);
  const [isSticky, ref] = useDetectSticky();
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [staked, setStaked] = useState(0);
  const [iagUsdm, setIagUsdm] = useState(0);
  const [epochs, setEpochs] = useState([483, 485]);
  const [delegatedAda, setDelegatedAda] = useState(0);
  const [stakeAmountError, setStakeAmountError] = useState("");
  const [iagUsdmError, setIagUsdmError] = useState("");
  const [delegatedError, setDelegatedError] = useState("");
  const [rewards, setRewards] = useState([]);

  const getEstimatedIagReward = () => {
    const estimated =
      ((totalDelegated * adaPrice + (tvl + tvlIagUsdm)) * 0.003) / iagPrice;
    return estimated;
  };

  // const getEstimatedIagRewardFromNuvola = () => {
  //   const apy = 3.5;
  //   const totalNulStaked = 420000;
  //   const totalNvlStakedInUsd = totalNulStaked * nvlPrice;
  //   const returnsInUsd = (totalNvlStakedInUsd * apy) / 100;
  //   const estimatedIag = returnsInUsd / iagPrice;
  //   return estimatedIag;
  // };

  const handleGlobalEditMode = (value) => {
    setGlobalSettingsEditMode(value);
  };

  const handleStakeAmountInputChange = (value) => {
    checkStakeDelegatedInputs(
      validateRange(value, 1, 100000000 * iagPrice * 2),
      tvl,
      setStaked,
      setStakeAmountError
    );
  };

  const handleStakeAmountRangeChange = (value) => {
    checkStakeDelegatedInputs(value[0], tvl, setStaked, setStakeAmountError);
  };

  const handleIagUsdmInputChange = (value) => {
    checkStakeDelegatedInputs(
      validateRange(value, 1, 100000000 * iagPrice * 2),
      tvlIagUsdm,
      setIagUsdm,
      setIagUsdmError
    );
  };

  const handleIagUsdmRangeChange = (value) => {
    checkStakeDelegatedInputs(
      value[0],
      tvlIagUsdm,
      setIagUsdm,
      setIagUsdmError
    );
  };

  const delegatedAdaChange = (value) => {
    checkStakeDelegatedInputs(
      [validateRange(value, 0, totalDelegated)],
      totalDelegated,
      setDelegatedAda,
      setDelegatedError
    );
  };

  const handleDelegatedAdaRangeChange = (value) => {
    checkStakeDelegatedInputs(
      value[0],
      totalDelegated,
      setDelegatedAda,
      setDelegatedError,
      "ADA delegated in total"
    );
  };

  const checkStakeDelegatedInputs = (
    input,
    globalValue,
    setAmount,
    setError
  ) => {
    if (input > globalValue && globalValue !== 0.0) {
      setAmount(globalValue);
    } else if (input >= 0 && input <= globalValue) {
      setAmount(input);
      setError("");
    }
  };

  useEffect(() => {
    const calculated = calculateRewards(
      staked + iagUsdm,
      delegatedAda,
      tvl + tvlIagUsdm,
      totalDelegated,
      adaPrice,
      iagPrice,
      nvlPrice,
      saturnPrice
    );
    const relativeEpoch = epochs[1] - epochs[0] + 1;
    setRewards({
      iag: calculated.iag[relativeEpoch],
      nvl: calculated.nvl[relativeEpoch],
      saturn: calculated.saturn[relativeEpoch],
    });
  }, [
    staked,
    iagUsdm,
    epochs,
    delegatedAda,
    iagPrice,
    adaPrice,
    nvlPrice,
    saturnPrice,
    tvl,
    tvlIagUsdm,
    totalDelegated,
  ]);

  return (
    <div>
      <PageHeading>LSPO 2 Reward Calculator</PageHeading>
      <StyledRow style={{ padding: "0", columnGap: "24px" }}>
        <StakeGlobalColumn lg={6} md={12}>
          <TextButton
            onClick={() => (window.location.href = "/")}
            style={{ margin: "10px" }}
          >
            <BackImg src={arrowBack} /> Go Back
          </TextButton>
          <StakeCard borderRadius="8px">
            <InputMainWrapper>
              {/* liquidity provided IAG/ADA*/}
              <InputRangeWrapper>
                <LabelInputWrapper>
                  <LabelInfo>Amount of liquidity provided (IAG/ADA)</LabelInfo>
                  <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                    <Input
                      placeholder="Amount of liquidity provided (IAG/ADA)"
                      validation={false}
                      value={formatNumbersEur(staked.toString())}
                      onChange={handleStakeAmountInputChange}
                      min={0}
                      max={100}
                      errorMessage={stakeAmountError}
                      setErrorMessage={setStakeAmountError}
                      disabled={loadingInfo}
                    ></Input>
                  </div>
                  <LabelInfo style={{ color: theme.inputValidation }}>
                    {stakeAmountError}
                  </LabelInfo>
                </LabelInputWrapper>
                <RangeBar
                  minValue="0"
                  maxValue={tvl}
                  step="100"
                  value={[staked]}
                  onChange={handleStakeAmountRangeChange}
                  disabled={loadingInfo}
                />
                <RangeGuide>
                  <LabelInfo>0</LabelInfo>
                  <LabelInfo>{formatNumbersEur(tvl.toFixed(0))}</LabelInfo>
                </RangeGuide>
              </InputRangeWrapper>
              {/* liquidity provided IAG/USDM*/}
              <InputRangeWrapper>
                <LabelInputWrapper>
                  <LabelInfo>Amount of liquidity provided (IAG/USDM)</LabelInfo>
                  <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                    <Input
                      placeholder="Amount of liquidity provided (IAG/USDM)"
                      validation={false}
                      value={formatNumbersEur(iagUsdm.toString())}
                      onChange={handleIagUsdmInputChange}
                      min={0}
                      max={tvlIagUsdm}
                      errorMessage={iagUsdmError}
                      setErrorMessage={setIagUsdmError}
                      disabled={loadingInfo}
                    ></Input>
                  </div>
                  <LabelInfo style={{ color: theme.inputValidation }}>
                    {iagUsdmError}
                  </LabelInfo>
                </LabelInputWrapper>
                <RangeBar
                  minValue="0"
                  maxValue={tvlIagUsdm}
                  step="100"
                  value={[iagUsdm]}
                  onChange={handleIagUsdmRangeChange}
                  disabled={loadingInfo}
                />
                <RangeGuide>
                  <LabelInfo>0</LabelInfo>
                  <LabelInfo>
                    {formatNumbersEur(tvlIagUsdm.toFixed(0))}
                  </LabelInfo>
                </RangeGuide>
              </InputRangeWrapper>
              {/* epoch */}
              <InputRangeWrapper>
                <LabelInputWrapper>
                  <LabelInfo>Duration (epochs)</LabelInfo>
                </LabelInputWrapper>
                <TwoThumbsDraggableTrack
                  minValue="483"
                  maxValue="555"
                  step="1"
                  values={epochs}
                  onChange={(values) => {
                    setEpochs(values);
                  }}
                  disabled={loadingInfo}
                />
                <RangeGuide>
                  <LabelInfo>483</LabelInfo>
                  <LabelInfo>555</LabelInfo>
                </RangeGuide>
              </InputRangeWrapper>
              <InfoCard borderRadius="8px">
                <LabelInfoCard>
                  Epoch is a rewards cycle of 5 days. The{" "}
                  <a
                    href="https://azureada.com/epoch-calendar/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: theme.epochCalendar,
                      textDecoration: "none",
                    }}
                  >
                    Epoch Calendar
                  </a>{" "}
                  allows you to see exactly when the rewards are paid out
                </LabelInfoCard>
              </InfoCard>
              {/* ada delegated */}
              <InputRangeWrapper>
                <LabelInputWrapper>
                  <LabelInfo>ADA delegated to (IAGL1 & IAGL2)</LabelInfo>
                  <Input
                    placeholder="ADA delegated"
                    validation={false}
                    value={formatNumbersEur(delegatedAda.toString())}
                    onChange={delegatedAdaChange}
                    min={0}
                    max={totalDelegated}
                    errorMessage={delegatedError}
                    setErrorMessage={setDelegatedError}
                    disabled={loadingInfo}
                  />{" "}
                  <LabelInfo style={{ color: theme.inputValidation }}>
                    {delegatedError}
                  </LabelInfo>
                </LabelInputWrapper>
                <RangeBar
                  minValue="0"
                  maxValue={totalDelegated}
                  step="100"
                  value={[delegatedAda]}
                  onChange={handleDelegatedAdaRangeChange}
                  disabled={loadingInfo}
                />
                <RangeGuide>
                  <LabelInfo>0</LabelInfo>
                  <LabelInfo>
                    {formatNumbersEur(totalDelegated.toFixed(0))}
                  </LabelInfo>
                </RangeGuide>
              </InputRangeWrapper>
            </InputMainWrapper>
          </StakeCard>
          <NotesWrapper>
            <NoteCard borderRadius="8px">
              <Title>Notes:</Title>
              <NotesInfo>
                1. The length and weight factors of LSPO 1 will be carried to
                LSPO 2 while calculating total rewards.
              </NotesInfo>
              <NotesInfo>
                2. Any changes/addition to rewards tokens will be announced.
              </NotesInfo>
              <NotesInfo>
                3. Returns on the stake pool are assumed to be 3% to calculate
                IAG rewards. The actuals will be known during buyback.
              </NotesInfo>
              <NotesInfo>
                4. There will be more rewards (IAG, FACT, COPI, MNT - Minutes Network)
                from Nuvola staking which will also be included as they are
                added.
              </NotesInfo>
            </NoteCard>
          </NotesWrapper>
        </StakeGlobalColumn>
        <DisplayColumn lg={6} md={12} style={{ padding: "0" }}>
          <TextButton style={{ margin: "10px", opacity: 0, cursor: "default" }}>
            <BackImg src={arrowBack} /> Go Back
          </TextButton>
          <DisplayContainer className="appear_animation-2s transform-center">
            <DisplayContainer className="appear_animation-2s transform-center">
              <DisplayRewardWrapper>
                <Lspo2Rewards rewards={rewards} epoch={epochs} />
              </DisplayRewardWrapper>
              <GlobalSettings
                iagUsdRate={iagPrice}
                adaPrice={adaPrice}
                isOnEdit={globalSettingsEditMode}
                setIsOnEdit={handleGlobalEditMode}
                estimatedIagReward={getEstimatedIagReward()}
                // estimatedIagRewardFromNuvola={getEstimatedIagRewardFromNuvola()}
                isLspo2
              />
            </DisplayContainer>
          </DisplayContainer>
        </DisplayColumn>
      </StyledRow>
      <NotesWrapperMobile>
        <NoteCard borderRadius="8px">
          <Title>Notes:</Title>
          <NotesInfo>
            1. The length and weight factors of LSPO 1 will be carried to LSPO 2
            while calculating total rewards.
          </NotesInfo>
          <NotesInfo>
            2. Any changes/addition to rewards tokens will be announced.
          </NotesInfo>
          <NotesInfo>
            3. Returns on the stake pool are assumed to be 3% to calculate IAG
            rewards. The actuals will be known during buyback.
          </NotesInfo>
          <NotesInfo>
            4. There will be more rewards (IAG, FACT, COPI, MNT - Minutes Network)
            from Nuvola staking which will also be included as they are added.
          </NotesInfo>
        </NoteCard>
      </NotesWrapperMobile>
      <ShadowIndicator ref={ref} />

      <DisplayRewardWrapperMobile issticky={isSticky ? 0 : 1}>
        <Lspo2Rewards rewards={rewards} epoch={epochs} />
      </DisplayRewardWrapperMobile>
      <GlobalSettingsWrapperMobile>
        <GlobalSettings
          className="appear_animation-2s transform-center"
          iagUsdRate={iagPrice}
          adaPrice={adaPrice}
          isOnEdit={globalSettingsEditMode}
          setIsOnEdit={handleGlobalEditMode}
          estimatedIagReward={getEstimatedIagReward()}
          // estimatedIagRewardFromNuvola={getEstimatedIagRewardFromNuvola()}
          isLspo2
        />
      </GlobalSettingsWrapperMobile>
    </div>
  );
};

export default Lspo2;
