import styled from "styled-components";
import { Card } from "../../components/Cards/Card";

export const PageHeading = styled.h1`
  font-size: 26px;
  color: ${(props) => props.theme.fontColor};
  margin-top: 12px;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 640px) {
    text-align: center;
    font-size: 20px;
    margin-left: 15px;
    /* margin-left:20px; */
  }
`;

export const ChooseLspoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ChooseLspoCard = styled(Card)`
  max-width: 50%;
  align-items: start;
  background: ${(props) => props.theme.ispoCardBg};
  padding: 1.5rem 30px 1.5rem 30px;
  box-shadow: none;
  border-radius: 8px !important;
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 16px;
  @media (max-width: 1439px) {
    max-width: 100%;
    padding: 1.5rem 15px 1.5rem 15px;
  }
  @media (max-width: 1008px) {
    max-width: 100%;
    padding: 1.2rem 1.5rem 1.5rem 1.5rem;
  }
  @media (max-width: 640px) {
    max-width: 100%;
    flex-direction: column;
    padding: 35px;
  }
`;

export const DescriptionCard = styled(Card)`
  max-width: 50%;
  align-items: start;
  background: ${(props) => props.theme.ispoCardBg};
  padding: 1.5rem 30px 1.5rem 30px;
  box-shadow: none;
  border-radius: 8px !important;
  margin-bottom: 16px;
  @media (max-width: 1439px) {
    max-width: 100%;
  }
  @media (max-width: 1008px) {
    max-width: 100%;
  }
  @media (max-width: 640px) {
    max-width: 100%;
  }
`;

export const LabelInfo = styled.p`
  margin: -5px 0;
  font-size: 14px;
  color: ${(props) => props.theme.addressInputPlaceholder};
`;
export const Title = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 3px;
  color: ${(props) => props.theme.fontColor};
`;
export const ChooseButton = styled.a`
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.chooseBorder};
  width: 50%;
  padding: 15px;
  cursor: pointer;
  @media (max-width: 640px) {
    width: 100%;
  }
  &:hover {
    outline: none;
    border: 1px solid
      ${(props) =>
        props.value !== ""
          ? props.theme.inputFilledHovered
          : props.theme.inputEmptyHovered};
  }
`;
