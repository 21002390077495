import { createContext, useContext, useState, useEffect } from "react";
import {
  fetchCoinPrice,
  fetchCoinPriceByEpoch,
  fetchRewardDistribution,
  fetchTVL,
  fetchTVLIagUsdm,
  fetchTVLIagUsdmV2,
  fetchTVLPerEpoch,
  fetchTotalDelegatedAda,
  fetchTotalDelegatedPerEpoch,
} from "../api/apiService";

export const APIContext = createContext();

export const useAPIContext = () => useContext(APIContext);

export const APIProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [configError, setConfigError] = useState(false);
  const [adaPricePerEpoch, setAdaPricePerEpoch] = useState([]);
  const [iagPricePerEpoch, setIagPricePerEpoch] = useState([]);
  const [stakedPerEpoch, setStakedPerEpoch] = useState([]);
  const [delegatedPerEpoch, setDelegatedPerEpoch] = useState([]);
  const [rewardDistribution, setRewardDistribution] = useState([]);
  const [nvlPrice, setNvlPrice] = useState(0);
  const [saturnPrice, setSaturnPrice] = useState(0);
  const [tvl, setTvl] = useState(0);
  const [tvlIagUsdm, setTvlIagUsdm] = useState(0);
  const [totalDelegated, setTotalDelegated] = useState(0);

  const loadConfig = async () => {
    setLoading(true);
    try {
      const [
        adaPrice,
        iagPrice,
        staked,
        delegated,
        rewards,
        nvlPrice,
        saturnPrice,
        tvl,
        tvlIagUsdm,
        tvlIagUsdmV2,
        totalDelegated,
      ] = await Promise.all([
        fetchCoinPriceByEpoch("ada"),
        fetchCoinPriceByEpoch("iag"),
        fetchTVLPerEpoch(),
        fetchTotalDelegatedPerEpoch(),
        fetchRewardDistribution(),
        fetchCoinPrice("nvl"),
        fetchCoinPrice("saturn"),
        fetchTVL(),
        fetchTVLIagUsdm(),
        fetchTVLIagUsdmV2(),
        fetchTotalDelegatedAda(),
      ]);
      setAdaPricePerEpoch(adaPrice);
      setIagPricePerEpoch(iagPrice);
      setStakedPerEpoch(staked);
      setDelegatedPerEpoch(delegated?.delegated);
      setRewardDistribution(rewards);
      setNvlPrice(nvlPrice);
      setSaturnPrice(saturnPrice);
      setTvl(tvl.tvlUsd);
      setTvlIagUsdm(tvlIagUsdm.tvlUsd + tvlIagUsdmV2.tvlUsd);
      setTotalDelegated(totalDelegated.delegated);
      setConfigError(
        !adaPrice ||
          !iagPrice ||
          !staked ||
          !delegated ||
          !rewardDistribution ||
          !nvlPrice ||
          !saturnPrice ||
          !tvl ||
          !tvlIagUsdm ||
          !totalDelegated
      );
    } catch (e) {
      setConfigError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <APIContext.Provider
      value={{
        loading,
        configError,
        adaPricePerEpoch,
        iagPricePerEpoch,
        stakedPerEpoch,
        delegatedPerEpoch,
        rewardDistribution,
        nvlPrice,
        saturnPrice,
        tvl,
        tvlIagUsdm,
        totalDelegated,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};
