import React from "react";
import { useTheme } from "styled-components";
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";

export default function DarkLightBtn({ customStyle, mobileView, themeSwitch }) {
  const theme = useTheme();

  return (
    <button
      aria-label="Toggle Dark Mode"
      type="button"
      className={`${customStyle} rounded-lg mt-1`}
      onClick={themeSwitch}
    >
      <div className="flex items-center -mt-1">
        <div
          className={` ${
            theme.type === "dark" ? "icon-box-dark" : "icon-box-light"
          } h-10 group overflow-hidden relative w-16 rounded-lg py-[0.5rem] px-0.5 flex gap-2`}
        >
          <SunIcon
            className={`h-6 mr-0.5 absolute right-0 fill-white z-10 ${
              theme.type === "dark"
                ? "-translate-x-full opacity-0"
                : "group-hover:mr-1"
            } ease-in-out duration-500 `}
          />
          <div
            className={`h-6 w-6 -ml-0.5  group-hover:w-7 bg-white rounded-full z-50 ease-in-out duration-500  ${
              theme.type === "dark"
                ? "translate-x-[2.125rem] group-hover:-ml-1.5"
                : "ml-0.5 "
            } `}
          >
            {" "}
          </div>
          <MoonIcon
            className={`h-6 absolute right-1 group-hover:-mr-0.5 fill-white ease-in-out z-10 duration-500  ${
              theme.type === "dark"
                ? "-translate-x-[2rem]"
                : "-translate-x-[2.125rem] opacity-0"
            }`}
          />
        </div>
        {theme.type === "dark" ? (
          <span className={`${mobileView ? "hidden" : "hidden"} pl-4`}>
            Dark theme
          </span>
        ) : (
          <span className={`${mobileView ? "hidden" : "hidden"} pl-4`}>
            Light theme
          </span>
        )}
      </div>
    </button>
  );
}
