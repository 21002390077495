import styled from "styled-components";
import { Card } from "../../components/Cards/Card";

export const DisplayCard = styled(Card)`
  width: 100%;
  align-items: start;
  background: ${(props) => props.theme.ispoCardBg};
  box-shadow: none;
  padding: 1.5rem;
  margin-bottom: 15px;
  @media (max-width: 1008px) {
    border-radius: 0;
    padding: 1.1rem 15px 15px 15px;
  }
`;
export const Label = styled.p`
  font-size: 20px;
  margin: -5px 0;
  word-break: break-word;
`;
