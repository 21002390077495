import { CrossIcon, SuccessIconText, ToastDesc, ToastTitle, ToastWrap, TopWrapper } from "./styles.elements";
import cross_dark from "../../assets/icons/cross_dark.svg";
import cross_light from "../../assets/icons/cross_light.svg";
import toast from "react-hot-toast";
import { useTheme } from "styled-components";


const Toast = ({ id,icon,title,text }) => {
    const theme = useTheme();
    return (
      <ToastWrap>
        <TopWrapper>
          <SuccessIconText>
            <img src={icon} alt={`toast icon`} />
            <ToastTitle>{title}</ToastTitle>
          </SuccessIconText>
          <button onClick={() => toast.dismiss(id)}>
            <CrossIcon
              src={theme.type === "light" ? cross_light : cross_dark}
              alt={`cross icon ${theme.type}`}
            />
          </button>
        </TopWrapper>
        <ToastDesc>
          {text}
        </ToastDesc>
      </ToastWrap>
    );
  };
  export default Toast;