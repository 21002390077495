import axios from "axios";
// import { getCoinPricePerEpoch } from './coinPricePerEpoch';

const baseUrl = process.env.REACT_APP_BASE_URL;

const handleAxiosError = (e) => {
  if (e.request) {
    return { success: false, error: "No response received from the server" };
  } else if (e.response) {
    return {
      success: false,
      error: `Request failed with status ${e.response.status}`,
    };
  } else {
    return { success: false, error: "Error setting up the request" };
  }
};

const get = async (
  url,
  errorCallBack = (e) => {
    console.log("Error:", e);
  },
  config = {}
) => {
  try {
    const res = await axios.get(`${baseUrl}/${url}`, config);
    return res.data;
  } catch (e) {
    errorCallBack(e);
    return handleAxiosError(e);
  }
};

export const post = async (url, body) => {
  try {
    const res = await axios.post(`${baseUrl}/${url}`, body);
    return res.data;
  } catch (e) {
    console.log("Error Posting: ", e);
    return handleAxiosError(e);
  }
};

export const fetchDistribution = async (amount) => {
  try {
    const res = await post("pools/liquidity/distribution", {
      amount: amount,
    });
    return res.data;
  } catch (e) {
    console.log(e);
    return handleAxiosError(e);
  }
};

export const fetchReward = async (body) => {
  try {
    const res = await post(`calculator/getreward`, body);
    return res.data;
  } catch (e) {
    console.log(e);
    return handleAxiosError(e);
  }
};

export const fetchRewardByAddress = async (body) => {
  try {
    const res = await post(`calculator/getrewardbyaddress`, body);
    return res.data;
  } catch (e) {
    console.log(e);
    return handleAxiosError(e);
  }
};

export const fetchCoinPrice = async (coin) => {
  try {
    const res = await get(`coins/price/${coin}`);
    return res?.data.price;
  } catch (e) {
    console.log(e);
    return handleAxiosError(e);
  }
};

export const fetchCoinPriceByEpoch = async (coin) => {
  try {
    const res = await get(`coins/price/${coin}/per-epoch`);
    return res.data;
  } catch (e) {
    console.log(e);
    return handleAxiosError(e);
  }
};

export const fetchTVL = async () => {
  try {
    const res = await get("pools/tvl/total");
    return res?.data;
  } catch (e) {
    console.log(e);
    return handleAxiosError(e);
  }
};

export const fetchTVLIagUsdm = async () => {
  try {
    const res = await get("pools/tvl/minswap/iag-usdm");
    return res?.data;
  } catch (e) {
    console.log(e);
    return handleAxiosError(e);
  }
};

export const fetchTVLIagUsdmV2 = async () => {
  try {
    const res = await get("pools/tvl/minswap/iag-usdm/v2");
    return res?.data;
  } catch (e) {
    console.log(e);
    return handleAxiosError(e);
  }
};

export const fetchTVLPerEpoch = async () => {
  try {
    const res = await get("pools/tvl/perepoch");
    return res?.data;
  } catch (e) {
    console.log(e);
    return handleAxiosError(e);
  }
};

export const fetchTotalDelegatedAda = async () => {
  try {
    const res = await get(`pools/delegated`);
    return res?.data;
  } catch (e) {
    console.log(e);
    return handleAxiosError(e);
  }
};

export const fetchTotalDelegatedPerEpoch = async () => {
  try {
    const res = await get(`pools/delegated/perepoch`);
    return res?.data;
  } catch (e) {
    return handleAxiosError(e);
  }
};

export const fetchRewardDistribution = async () => {
  try {
    const res = await get(`user/distribution`);
    return res?.data;
  } catch (e) {
    return handleAxiosError(e);
  }
};

export const fetchAmountHeld = async (address, errorCallBack, config = {}) => {
  const res = await get(`user/total/${address}`, errorCallBack, config);
  return res?.data;
};

export const fetchUserDelegatedAda = async (address, errorCallBack, config) => {
  const res = await get(`user/delegated/${address}`, errorCallBack, config);
  return res?.data;
};
