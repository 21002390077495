import { useState, useEffect } from "react";

export default function DropdownAnimation() {
  const [triggers, setTriggers] = useState(null);
  const [background, setBackground] = useState(null);
  const [nav, setNav] = useState(null);

  useEffect(() => {
    setTriggers(document.querySelectorAll(".menu > li"));
    setBackground(document.querySelector(".dropdownBackground"));
    setNav(document.querySelector(".top"));
  }, []);

  useEffect(() => {
    const handleEnter = (element) => {
      element.classList.add("trigger-enter");
      setTimeout(() => {
        if (element.classList.contains("trigger-enter")) {
          element.classList.add("trigger-enter-active");
        }
      }, 150);
      if (background) {
        background.classList.add("open");
        const dropdown = element.querySelector(".child");
        const dropdownCoords = dropdown?.getBoundingClientRect();
        const navCoords = nav?.getBoundingClientRect();

        const coords = {
          height: dropdownCoords?.height,
          width: dropdownCoords?.width,
          top: (dropdownCoords?.top ?? 0) - (navCoords?.top ?? 0),
          left: (dropdownCoords?.left ?? 0) - (navCoords?.left ?? 0),
        };

        background.style.setProperty("width", `${coords.width}px`);
        background.style.setProperty("height", `${coords.height}px`);
        background.style.setProperty(
          "transform",
          `translate(${coords.left}px,${coords.top}px)`
        );
      }
    };

    const handleLeave = (element) => {
      element.classList.remove("trigger-enter", "trigger-enter-active");
      if (background) {
        background.classList.remove("open");
      }
    };

    if (triggers) {
      triggers.forEach((trigger) =>
        trigger.addEventListener("mouseenter", () => handleEnter(trigger))
      );
      triggers.forEach((trigger) =>
        trigger.addEventListener("mouseleave", () => handleLeave(trigger))
      );
    }
    return () => {
      if (triggers) {
        triggers.forEach((trigger) =>
          trigger.removeEventListener("mouseenter", () => handleEnter(trigger))
        );
        triggers.forEach((trigger) =>
          trigger.removeEventListener("mouseleave", () => handleLeave(trigger))
        );
      }
    };
  }, [triggers, nav, background]);

  return <div />;
}
