import { calculateIagReward } from "./iag";
import { calculateNvlReward } from "./nvl";
import {calculateSaturnReward} from "./saturn";

// let adaPrice = 0.4331;
// let iagPrice = 0.14;
// let nvlPrice = 0.15;

export const calculateRewards = (
  userStaked,
  userDelegated,
  totalStaked,
  totalDelegated,
  adaPrice,
  iagPrice,
  nvlPrice,
  saturnPrice
) => {
  const iag = calculateIagReward(
    totalStaked,
    totalDelegated,
    userStaked,
    userDelegated,
    iagPrice,
    adaPrice
  );
  const nvl = calculateNvlReward(
    totalStaked,
    totalDelegated,
    userStaked,
    userDelegated,
    nvlPrice,
    adaPrice
  );
  const saturn = calculateSaturnReward(
    totalStaked,
    totalDelegated,
    userStaked,
    userDelegated,
      saturnPrice,
    adaPrice
  );
  return { iag, nvl, saturn };
};
