import React from "react";
import { Link } from "../Link";
import DropdownElement from "./dropdownElement";
import { useTheme } from "styled-components";
import join from "../../assets/menuIcons/become-a-member.svg";
import joinDark from "../../assets/menuIconsDark/become-a-member.svg";

export default function BecomeBetaTest() {
  const theme = useTheme();
  return (
    <>
      <hr
        className={`${theme.type === "dark" ? "bg-[#9f9bdd]" : "bg-[#ccd0d9]"}
        h-px border-none`}
      />
      <ul className="p-3">
        <li
          className={`${
            theme.type === "dark" ? "hover:bg-gray-500/50" : "hover:bg-gray-100"
          }
          group rounded-lg`}
        >
          <Link
            href="https://blog.iagon.com/iagon-trusted-tester-program-announced/"
            target="newTab"
          >
            <DropdownElement
              title="Become a beta tester"
              subTitle="Public storage platform"
              icon={join}
              iconDark={joinDark}
            />
          </Link>
        </li>
      </ul>
    </>
  );
}
