import React, { useEffect, useState } from "react";
import Input from "../../components/Input";
import {
  LabelInputWrapper,
  MainWrapper,
  StakeCard,
  AddressInputWrapper,
  EstimateRewardButton,
  ConnectWalletButton,
  DisconnectButton,
  BottomLabelInfo,
} from "./styles.elements";
import { LabelInfo } from "../DisplayRewards/styles.elements";
import errorIcon from "../../assets/icons/error_icon.svg";
import successIcon from "../../assets/icons/sucess_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../components/Toast";
import toast from "react-hot-toast";
import TabBar from "../../components/TabBar";
import { ConnectModal } from "../ConnectModal";
import { setUserWallet } from "../../redux/constants";
import CircularProgress from "@mui/material/CircularProgress";
import { getAddress } from "./wallet";
import axios from "axios";

const StakeInput = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.calculatorReducer);
  const [tabPosition, setTabPosition] = useState(0);
  const [walletName, setWalletName] = useState(undefined);
  const [modalShow, setModalShow] = useState(false);
  const [addressValid, setAddressValid] = useState(true);
  const [addressError, setAddressError] = useState("");
  const {
    address,
    setAddress,
    loadingInfo,
    setLoadingInfo,
    setRewards,
    setSummary,
  } = props;

  useEffect(() => {
    if (walletName) {
      handleConnect(walletName);
    }
  }, [walletName]);

  const handleConnect = async (walletName) => {
    const { _address, _wallet, _error } = await getAddress(walletName);
    if (!_error) {
      toast((t) => (
        <Toast
          id={t.id}
          icon={successIcon}
          title="Connected!"
          text={_wallet.message}
        />
      ));
      setAddress(_address);
      dispatch(setUserWallet({ wallet: _address }));
    } else {
      dispatch(setUserWallet({ wallet: undefined }));
      setWalletName(undefined);
      setAddress("");
      toast((t) => (
        <Toast
          id={t.id}
          icon={errorIcon}
          title="Could not connect"
          text={_wallet.message}
        />
      ));
    }
  };

  const handleDisconnect = () => {
    toast((t) => (
      <Toast
        id={t.id}
        icon={successIcon}
        title="Disconnected!"
        text={`${walletName} disconnected successfully`}
      />
    ));
    setAddress("");
    setWalletName(undefined);
    dispatch(setUserWallet({ wallet: undefined }));
    // props.handleReset();
  };

  const handleModalApprove = () => {
    setModalShow(false);
  };

  const handleModalReject = () => {
    setModalShow(false);
  };

  const validateStakeKey = (key) => {
    return key.startsWith("stake") && key.length === 59;
  };

  useEffect(() => {
    if (address !== "" && !loadingInfo) {
      getAddressDetails();
    }
  }, [address]);

  const getAddressDetails = async () => {
    if (address !== "") {
      if (validateStakeKey(address)) {
        setLoadingInfo(true);
        setAddressValid(true);
        setAddressError("");
        setRewards([]);
        setSummary(undefined);
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL_V2}/rewards/lspo1/fetch/with-details/${address}`
          );
          const rewardsData = res.data.data;
          setRewards(rewardsData.mapped);
          setSummary(rewardsData.summary)
          setLoadingInfo(false);
        } catch (e) {
          console.log(e);
        } finally {
          setLoadingInfo(false);
        }
      } else {
        setAddressError("Enter a valid cardano address");
        setAddressValid(false);
      }
    }
  };

  const onClickMyWallet = () => {
    if (!loadingInfo) {
      setAddress("");
      setTabPosition(0);
      setRewards([]);
      setSummary(undefined);
    }
  };

  const onClickOtherAddress = () => {
    if (!loadingInfo) {
      if (walletName) {
        handleDisconnect();
      }
      setTabPosition(1);
      setRewards([]);
      setSummary(undefined);
    }
  };

  return (
    <MainWrapper {...props}>
      <ConnectModal
        setWalletName={setWalletName}
        onApprove={handleModalApprove}
        onReject={handleModalReject}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <StakeCard borderRadius="8px">
        <TabBar
          tabPosition={tabPosition}
          myWallet={onClickMyWallet}
          otherAddress={onClickOtherAddress}
        />
        <LabelInputWrapper style={{ marginTop: "10px", marginBottom: "10px" }}>
          <LabelInfo>Stake key</LabelInfo>
          {tabPosition === 0 ? (
            <AddressInputWrapper>
              <Input
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address}
                disabled={true}
                validation={false}
                errorMessage={addressError}
                setErrorMessage={setAddressError}
                optionalFormValid={addressValid}
              />
              {state.wallet ? (
                <DisconnectButton
                  disabled={loadingInfo}
                  onClick={handleDisconnect}
                >
                  {loadingInfo ? (
                    <CircularProgress size="1.5rem" color="inherit" />
                  ) : (
                    "Disconnect"
                  )}
                </DisconnectButton>
              ) : (
                <ConnectWalletButton onClick={() => setModalShow(true)}>
                  {loadingInfo ? (
                    <CircularProgress size="1.5rem" color="inherit" />
                  ) : (
                    "Connect Wallet"
                  )}
                </ConnectWalletButton>
              )}
            </AddressInputWrapper>
          ) : (
            <AddressInputWrapper>
              <Input
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address}
                validation={false}
                errorMessage={addressError}
                setErrorMessage={setAddressError}
                optionalFormValid={addressValid}
                disabled={loadingInfo}
              />
              <EstimateRewardButton
                disabled={loadingInfo || address === ""}
                onClick={() => {
                  getAddressDetails();
                }}
              >
                {loadingInfo ? (
                  <CircularProgress size="1.5rem" color="inherit" />
                ) : (
                  "Estimate Reward"
                )}
              </EstimateRewardButton>
            </AddressInputWrapper>
          )}
        </LabelInputWrapper>
      </StakeCard>
    </MainWrapper>
  );
};

export { StakeInput };
