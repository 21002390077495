import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";

import { calculatorReducer } from "../calculatorReducer";

const middlewares = [thunk];
const rootReducer = combineReducers({ calculatorReducer });
const store = createStore(rootReducer, applyMiddleware(...middlewares));

export { store };
