import React, { useState } from "react";
import DisclamerDialog from "../disclamer";
import { formatNumbersEur } from "../../../helper";
import {
  DisclaimerLabel,
  DisclaimerSpan,
  DisplayCard,
  Label,
  RewardsWrapper,
  TokenReward,
  Title,
  InfoCard,
  TokenLabel,
  DetailCard,
  LabelCard,
  BoldSpan,
} from "./styles.elements";
import { useTheme } from "styled-components";
import expandMore from "../../../assets/icons/expand-more.svg";
import arrowDown from "../../../assets/icons/arrow-down.svg";

const Lspo2Rewards = ({ rewards, epoch }) => {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  return (
    <div>
      <DisclamerDialog open={open} setOpen={setOpen} />
      <DisplayCard
        className="appear_animation-2s transform-center"
        borderRadius="8px"
      >
        <RewardsWrapper>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: 'center'
            }}
          >
            <Title>
              Rewards Estimated from {epoch[0]} to {epoch[1]}
            </Title>
            <img
              style={{
                height: theme.type === "dark" ? "15px" : "20px",
                cursor: "pointer",
                transform: `rotate(${expanded ? "180" : "0"}deg)`,
                marginRight: "10px",
              }}
              alt="expand"
              src={theme.type === "dark" ? arrowDown : expandMore}
              onClick={() => setExpanded(!expanded)}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "20px",
              marginBottom: "20px",
              width: "100%",
              alignItems: "stretch",
            }}
          >
            {expanded && (
              <>
                <InfoCard borderRadius="8px">
                  <LabelCard>
                    <TokenLabel>IAGON</TokenLabel>
                  </LabelCard>
                  <DetailCard>
                    <Label>
                      IAG{" "}
                      <BoldSpan>
                        {formatNumbersEur(
                          (rewards.iag?.iagReward ?? 0).toFixed(6)
                        )}
                      </BoldSpan>
                    </Label>
                    <Label>
                      USD
                      <BoldSpan>
                        {Number(rewards.iag?.dollarReward ?? 0).toFixed(2)}
                      </BoldSpan>
                    </Label>
                    <Label>
                      APY
                      <BoldSpan>
                        {Number(rewards.iag?.apy || 0).toFixed(6)}%
                      </BoldSpan>
                    </Label>
                  </DetailCard>
                </InfoCard>
                <InfoCard borderRadius="8px">
                  <LabelCard>
                    <TokenLabel>SATURN</TokenLabel>
                  </LabelCard>
                  <DetailCard>
                    <Label>
                      Saturn{" "}
                      <BoldSpan>
                        {formatNumbersEur(
                          (rewards.saturn?.saturnReward ?? 0).toFixed(6)
                        )}
                      </BoldSpan>
                    </Label>
                    <Label>
                      USD
                      <BoldSpan>
                        {Number(rewards.saturn?.dollarReward ?? 0).toFixed(2)}
                      </BoldSpan>
                    </Label>
                    <Label>
                      APY
                      <BoldSpan>
                        {Number(rewards.saturn?.apy || 0).toFixed(6)}%
                      </BoldSpan>
                    </Label>
                  </DetailCard>
                </InfoCard>
                <InfoCard borderRadius="8px">
                  <LabelCard>
                    <TokenLabel>NUVOLA</TokenLabel>
                  </LabelCard>
                  <DetailCard>
                    <Label>
                      NVL{" "}
                      <BoldSpan>
                        {formatNumbersEur(
                          (rewards.nvl?.nvlReward ?? 0).toFixed(6)
                        )}
                      </BoldSpan>
                    </Label>
                    <Label>
                      USD
                      <BoldSpan>
                        {Number(rewards.nvl?.dollarReward ?? 0).toFixed(2)}
                      </BoldSpan>
                    </Label>
                    <Label>
                      APY
                      <BoldSpan>
                        {Number(rewards.nvl?.apy || 0).toFixed(6)}%
                      </BoldSpan>
                    </Label>
                  </DetailCard>
                </InfoCard>
              </>
            )}
            <InfoCard borderRadius="8px">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "15px",
                }}
              >
                <TokenLabel>Total APY</TokenLabel>
                <TokenLabel>
                  {" "}
                  {Number(
                    (rewards.nvl?.apy || 0) +
                      (rewards.saturn?.apy || 0) +
                      (rewards.iag?.apy || 0)
                  ).toFixed(6)}
                  %
                </TokenLabel>
              </div>
            </InfoCard>
          </div>
          <DisclaimerLabel>
            By using this calculator, you agree to the
            <DisclaimerSpan onClick={() => setOpen(true)}>
              Disclaimer
            </DisclaimerSpan>
          </DisclaimerLabel>
        </RewardsWrapper>
      </DisplayCard>
    </div>
  );
};

export default Lspo2Rewards;
