import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import {
  DisclaimerContent,
  DisclaimerText,
  DisclaimerTitle,
  UnderstoodButton,
  DisclaimerLi,
} from "./styles.elements";

export default function DisclamerDialog(props) {
  const { open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
      >
        <DisclaimerContent>
          <DisclaimerTitle>
            Disclaimer Liquidity Reward Program of Iagon
          </DisclaimerTitle>
          <br />
          <DisclaimerText>
            Disclaimer: The following legal disclaimer for the Liquidity Reward
            Program of Iagon ("Program") is based on applicable law. By
            participating in the Program, you agree to the terms and conditions
            outlined in this disclaimer. This disclaimer should be read in
            conjunction with the Terms of Use of Iagon, available at
            https://www.iagon.com/terms-of-use . In case of any discrepancies
            between this disclaimer and the Terms of Use, the latter shall
            prevail.
          </DisclaimerText>
          <ul style={{ listStyle: "initial" }}>
            <DisclaimerLi>
              Program Nature: The Liquidity Reward Program is a promotional
              initiative designed to incentivize participants who contribute
              liquidity to the Iagon platform. The Program is not intended as an
              investment opportunity or a solicitation for investment in any
              form. Participants should not construe participation in the
              Program as an offer to purchase, sell, or trade any securities,
              digital assets, or financial instruments.
            </DisclaimerLi>
            <DisclaimerLi>
              No Financial Advice: The information provided in relation to the
              Program does not constitute financial, investment, or legal
              advice. Participants are solely responsible for assessing the
              risks associated with their participation in the Program and
              should consult with independent professional advisors before
              making any decisions.
            </DisclaimerLi>
            <DisclaimerLi>
              Compliance with Applicable Laws: Participants are responsible for
              complying with all applicable laws, regulations, and guidelines in
              their respective jurisdictions. Iagon does not guarantee that
              participation in the Program is compliant with the laws of any
              specific jurisdiction. Participants must ensure that their
              participation in the Program is lawful and permitted under the
              laws applicable to them.
            </DisclaimerLi>
            <DisclaimerLi>
              Voluntary Participation: Participation in the Program is
              voluntary, and participants acknowledge that they are
              participating at their own risk. Iagon is not liable for any loss,
              damage, or liability arising from participation in the Program,
              including but not limited to financial losses, operational
              disruptions, or data breaches.
            </DisclaimerLi>
            <DisclaimerLi>
              Program Modifications: Iagon reserves the right to modify,
              suspend, or terminate the Program at any time without prior
              notice. Such modifications may include changes to eligibility
              criteria, reward structures, or termination of the Program
              altogether. Participants will not be entitled to any compensation
              or claims as a result of such modifications or termination.
            </DisclaimerLi>
            <DisclaimerLi>
              No Warranty: Iagon provides the Program on an "as is" and "as
              available" basis. Iagon does not provide any warranties, express
              or implied, regarding the accuracy, reliability, or availability
              of the Program. Participants acknowledge that the rewards
              calculated using the provided calculator app are approximate
              estimates and the actual rewards may differ from the estimates.
            </DisclaimerLi>
            <DisclaimerLi>
              Limitation of Liability: To the maximum extent permitted by 
              applicable law, Iagon, its officers, directors, employees,
              affiliates, agents, and partners shall not be liable for any
              direct, indirect, incidental, consequential, or special damages
              arising out of or in any way connected with the Program, including
              but not limited to damages for loss of profits, data, or other
              intangible losses.
            </DisclaimerLi>
            <DisclaimerLi>
              Indemnification: Participants agree to indemnify and hold harmless
              Iagon, its officers, directors, employees, affiliates, agents, and
              partners from any claims, liabilities, damages, losses, or
              expenses, including legal fees, arising from their participation
              in the Program or any violation of this disclaimer or applicable
              laws.
            </DisclaimerLi>
            <DisclaimerLi>
              Governing Law and Jurisdiction: This disclaimer and the Program
              shall be governed by and construed in accordance with applicable laws.
              Any disputes arising out of or in connection with this
              disclaimer or the Program shall be subject to the exclusive
              jurisdiction of the courts.
            </DisclaimerLi>
          </ul>
          <DisclaimerText>
            By participating in the Liquidity Reward Program, you acknowledge
            that you have read, understood, and agreed to this disclaimer and
            the Terms of Use of Iagon. If you do not agree with any part of this
            disclaimer or the Terms of Use, you should refrain from
            participating in the Program.
          </DisclaimerText>
          <DialogActions>
            <UnderstoodButton onClick={handleClose}>
              I Understand
            </UnderstoodButton>
          </DialogActions>
        </DisclaimerContent>
      </Dialog>
    </div>
  );
}
