import { Toaster } from "react-hot-toast"

const ToastProvider = ({position})=>{
    return(
      <Toaster
      position={position} 
      reverseOrder
      toastOptions={{
        style: {
          background:'transparent',
          border : 'none',
          boxShadow:'none'
        },
      }}
      />
    )
  }
  export default ToastProvider;
  