import { createGlobalStyle } from "styled-components";

export const lightTheme = {
  type: "light",
  aliceblue: "#F5F8FC",
  body: "#F6F8FC",
  fontColor: "#000",
  titleColor: "#2EABF1",
  buttonBg: "#2EABF1",
  buttonIspo: "white",
  buttonIspoTxt: "black",
  footerBg: "#F2F3F6",
  navColor: "#6A7280",
  cardColor: "#F0F7FD",
  gradientStart: "#ffffff",
  gradientEnd: "#F0F7FD",
  purple: "#fff",
  blueColor: "#fff",
  hover: "#D7D9DD",
  ispoCheckHeader: "black",
  addressInputBorder: "#80859B",
  addressInput: "#000",
  addressInputPlaceholder: "#80859B",
  addressInputBorderNoEmpty: "#80859B",
  checkInfoCardBg: "#F6F8FC",
  checkInfoCardTxt: "#80859B",
  ispoCardBg: "#ffffff",
  chipsBorder: "#80859B",
  iagonAddress: "#80859B",
  chipsBg: "#F9F9FB",
  chipsTxt: "#424B5A",
  barChartLabel: "#424B5A",
  rewardLabel: "#2D2350",
  rewardHeader: "#80859B",
  radialCircle: "#DDE2ED",
  radialValue: "#3A3A3C",
  tableTxt: "#424B5A",
  tableFirstCol: "#000000",
  buttonText: "#80859B",
  estimateRewardHeader: "#80859B",
  estimateRewardAmount: "#3C096C",
  estimateRewardUsd: "#80859B",
  estimateRewardApy: "#000000",
  tooltipBackground: "#fff",
  tooltipTextColor: "#80859B",
  editSettingBtn: "#6B38FB",
  rangeBarColor: "#6B38FB",
  rangeBarColorBg: "#F2F2FF",
  inputValidation:"#FF375F",
  epochCalendar:"#6B38FB",
  displayBoxShadow:" #80859B",
  inputFilledHovered:"#6837fb",
  inputEmptyHovered:"#6b36fb",
  inputFocusedEmpty:"#6B38FB",
  inputFocusedFilled:"#6B38FB",
  inputFilled:"#80859B",
  textBright: "#80859B",
  chooseBorder: '#CCD0D9',
  rewardHead: '#E2E6FF',
  rewardExpanded: '#ECEDF1'
};

export const darkTheme = {
  type: "dark",
  body: "#100f18",
  fontColor: "#fff",
  titleColor: "#2EABF1",
  buttonBg: "#2EABF1",
  buttonIspo: "black",
  buttonIspoTxt: "white",
  footerBg: "#20293A",
  navColor: "#CDD5E0",
  cardColor: "#20293A",
  gradientStart: "#0C1120",
  gradientEnd: "#20293A",
  purple: "#935FBB4D",
  blueColor: "#2EABF14D",
  hover: "#0c112066",
  ispoCheckHeader: "white",
  addressInputBorder: "#9F9BDD",
  addressInput: "#fff",
  addressInputPlaceholder: "#B5A9DF",
  addressInputBorderNoEmpty: "#9F9BDD",
  checkInfoCardBg: "#12101F",
  checkInfoCardTxt: "#6B6583",
  ispoCardBg: "#1b1926",
  chipsBorder: "#C8B2E5",
  iagonAddress: "#9F9BDD",
  chipsBg: "#2D2350",
  chipsTxt: "#B5A9DF",
  barChartLabel: "#9F9BDD",
  rewardLabel: "#C8B2E5",
  rewardHeader: "#FFFFFF",
  radialCircle: "#2F2353",
  radialValue: "#C8B2E5",
  tableTxt: "#9F9BDD",
  tableFirstCol: "#FFFFFF",
  buttonText: "#80859B",
  estimateRewardHeader: "#FFFFFF",
  estimateRewardAmount: "#9B59B6",
  estimateRewardUsd: "#9F9BDD",
  estimateRewardApy: "#CCD0D9",
  tooltipBackground: "#100F18",
  tooltipTextColor: "#EEE1FF",
  editSettingBtn: "#9069FF",
  rangeBarColor: "#9069FF",
  rangeBarColorBg: "#473F65",
  inputValidation:"#FF4B70",
  epochCalendar:"#9069FF",
  displayBoxShadow:" #100F18",
  inputFilledHovered:"#6B6583",
  inputEmptyHovered:"#6837fb",
  inputFocusedEmpty:"#9069FF",
  inputFocusedFilled:"#9069FF",
  inputFilled:"#9F9BDD",
  textBright: "#B5A9DF",
  chooseBorder: '#CCD0D9',
  rewardHead: '#2D2350',
  rewardExpanded: '#100F18'
};

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.body};
    color: ${(props) => props.theme.fontColor};
    /* contain: paint; */
  }

  ul {
    list-style: none;
  }

  *, button, input {
    border: 0;
    background: none;
    font-family: Karla;  }
`;
