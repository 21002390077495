import styled from "styled-components";
import { Card } from "../../components/Cards/Card";
import { FormSelect } from "react-bootstrap";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 1008px) {
    flex-direction: column;
  }

  /* column-gap:5rem; */
`;
export const InputButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  column-gap: 1.5rem;
  width: 100%;
`;
export const LabelInputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-direction: column;
  row-gap: 0.6rem;
  width: 100%;
`;
export const InputMainWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
`;
export const InputRangeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-direction: column;
  row-gap: 0.6rem;
  width: 100%;
`;
export const InfoImage = styled.img``;
export const InputLabel = styled.div`
  border: none;
  font-family: inherit;
  font-size: 0.8rem;
  /* padding-right: 1rem; */
  color: ${(props) => props.theme.addressInputPlaceholder};
`;
export const RangeGuide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: -10px;
`;
export const ToolTipIcon = styled.img`
  cursor: pointer;
  background-color: transparent;
`;
export const StakeCard = styled(Card)`
  width: 100%;
  align-items: flex-start;
  row-gap: 1.5rem;
  background: ${(props) => props.theme.ispoCardBg};
  margin-bottom: 20px;
  padding: 1.5rem 30px 1.5rem 30px;
  box-shadow: none;

  @media (max-width: 1439px) {
    padding: 1.5rem 15px 1.5rem 15px;
  }
  @media (max-width: 1008px) {
    padding: 1.2rem 1.5rem 1.5rem 1.5rem;
  }
  @media (max-width: 640px) {
    padding: 35px;
  }
`;

export const NoteCard = styled(Card)`
  width: 100%;
  align-items: flex-start;
  row-gap: 0.5rem;
  background: ${(props) => props.theme.ispoCardBg};
`;
export const InfoCard = styled(Card)`
  background-color: #f6f8fc;
  align-items: start;
  row-gap: 1rem;
  width: 100%;
  background: ${(props) => props.theme.checkInfoCardBg};
  box-shadow: none;
  padding: 16px 13px;
  margin-top: -13px;
  /* margin-bottom:-5px; */
  @media (max-width: 640px) {
    border-radius: 8px !important;
  }
`;

export const TextButton = styled.button`
  background: transparent;
  border: none;
  color: ${(props) => props.theme.buttonText};
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.editSettingBtn};
  &:hover {
    color: #6b37fa;
  }
`;

export const BackImg = styled.img`
  height: 15px;
  width: 15px;
`;

export const BottomLabelInfo = styled.p`
  margin: -5px 0;
  font-size: 14px;
  color: ${(props) => props.theme.addressInputPlaceholder};
  @media (max-width: 640px) {
    display: none;
  }
`;

export const EstimateRewardButton = styled.button`
  width: 260px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(128, 133, 155, 0.2);
  border: none;
  color: white;
  background-color: #6b38fb;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  @media (max-width: 640px) {
    width: 100%;
  }
  &:hover {
    background-color: rgba(107, 56, 251, 0.6);
    color: white;
  }
  &:disabled {
    opacity: 0.6;
  }
  &:focus {
    background-color: #6b38fb;
    color: white;
    box-shadow: none;
  }
  &:active {
    background-color: #6b38fb;
    transform: scale(0.99);
  }
`;

export const DisconnectButton = styled.button`
  width: 260px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(128, 133, 155, 0.2);
  border: none;
  color: #6b38fb;
  background-color: #e2e6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 640px) {
    width: 100%;
  }
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    opacity: 0.6;
  }
  &:active {
    transform: scale(0.99);
  }
`;

export const ConnectWalletButton = styled.button`
  width: 260px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(128, 133, 155, 0.2);
  border: none;
  color: white;
  background-color: #6b38fb;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 640px) {
    width: 100%;
  }
  &:hover {
    background-color: #6b38fb;
    color: white;
    opacity: 0.6;
  }
  &:focus {
    background-color: #6b38fb;
    color: white;
    box-shadow: none;
    opacity: 0.6;
  }
  &:active {
    background-color: #6b38fb;
    transform: scale(0.99);
  }
`;

export const AddressInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  align-items: center;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

export const CurrencySelect = styled(FormSelect)`
  color: ${(props) => props.theme.fontColor};
  height: 42px;
  width: 100px;
  border: 1px solid ${(props) => props.theme.addressInputBorder};
  border-radius: 10px;
  padding: 0rem 0.7rem;
  outline: none;
  background-color: ${(props) => props.theme.ispoCardBg};
  shadow: none;
`;
