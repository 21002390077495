import React from "react";
import { getTrackBackground, Range } from "react-range";
import { useTheme } from "styled-components";

const Index = (props) => {
  const theme = useTheme();

  const {step, minValue, maxValue, value, onChange, disabled = false} = props;
  const STEP = parseInt(step);
  const MIN = parseInt(minValue);
  const MAX = parseInt(maxValue);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        // margin: "1em",
        width: "100%"
      }}
    >
      <Range
        values={props.value}
        step={STEP}
        min={MIN}
        max={MAX}
        disabled={disabled}
        onChange={(values) => onChange(values)}
        renderTrack={({props, children}) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "40px",
              display: "flex",
              width: "100%"
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "10px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: value,
                  colors: [theme.rangeBarColor, theme.rangeBarColorBg],
                  min: MIN,
                  max: MAX
                }),
                alignSelf: "center",
                boxShadow: "inset 0 0 2px 0 rgba(128,133,155,0.2)"
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({props, isDragged}) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "32px",
              width: "32px",
              border: "2px solid #E0DEFB",
              borderRadius: "50%",
              backgroundColor: "#FFFFFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0 0 10px 0 #80859b33"

            }}
          >
          </div>
        )}
      />
      {
        /* <output style={{marginTop: "30px"}} id="output">
        {value[0].toFixed(1)}
      </output> */
      }
    </div>
  );
};

export default Index;
