import storage from "../assets/menuIcons/storage.svg";
import storageDark from "../assets/menuIconsDark/storage.svg";
import agora from "../assets/menuIcons/agora.svg";
import agoraDark from "../assets/menuIconsDark/agora.svg";
import ledger from "../assets/menuIcons/ledger.svg";
import ledgerDark from "../assets/menuIconsDark/ledger.svg";
import howItWorks from "../assets/menuIcons/how-it-works.svg";
import howItWorksDark from "../assets/menuIconsDark/how-it-works.svg";
import provideStorage from "../assets/menuIcons/provide-storage.svg";
import provideStorageDark from "../assets/menuIconsDark/provide-storage.svg";
import documentation from "../assets/menuIcons/whitepaper.svg";
import documentationDark from "../assets/menuIconsDark/whitepaper.svg";
import toolAndTechnology from "../assets/menuIcons/tool-and-technology.svg";
import toolAndTechnologyDark from "../assets/menuIconsDark/tool-and-technology.svg";
import calculator from "../assets/menuIcons/calculator.svg";
import calculatorDark from "../assets/menuIconsDark/calculator.svg";
import claim from "../assets/menuIcons/claim.svg";
import claimDark from "../assets/menuIconsDark/claim.svg";
import story from "../assets/menuIcons/our-story.svg";
import storyDark from "../assets/menuIconsDark/our-story.svg";
import team from "../assets/menuIcons/our-team.svg";
import teamDark from "../assets/menuIconsDark/our-team.svg";
import partner from "../assets/menuIcons/our-partner.svg";
import partnerDark from "../assets/menuIconsDark/our-partner.svg";
import contactUs from "../assets/menuIcons/contact.svg";
import contactUsDark from "../assets/menuIconsDark/contact.svg";
import join from "../assets/menuIcons/become-a-member.svg";
import joinDark from "../assets/menuIconsDark/become-a-member.svg";

export const Menu = [
  {
    menuName: "Products",
    path: "https://iagon.com/",
    subMenu: [
      {
        name: "Storage",
        desc: "Iagons core storage solution",
        menuIcon: storage,
        menuIconDark: storageDark,
        path: "https://iagon.com/storage",
      },
      {
        name: "Agora",
        desc: "Public storage platform",
        menuIcon: agora,
        menuIconDark: agoraDark,
        path: "https://iagon.com/storage#agora",
      },
      {
        name: "LedgerFlow",
        desc: "Multi-party transactions platform",
        menuIcon: ledger,
        menuIconDark: ledgerDark,
        path: "https://app.iagon.com/ledgerflow",
      },
    ],
  },
  {
    menuName: "Earn",
    path: "https://iagon.com/earn",
    subMenu: [
      {
        name: "How it works",
        desc: "Shared Storage Economy",
        menuIcon: howItWorks,
        menuIconDark: howItWorksDark,
        path: "https://iagon.com/earn#iagon-ambition",
      },
      {
        name: "Provide storage",
        desc: "Contribute and earn from it!",
        menuIcon: provideStorage,
        menuIconDark: provideStorageDark,
        path: "https://iagon.com/earn#iagon-ambitions",
      },
    ],
  },
  {
    menuName: "Technology",
    path: "https://iagon.com/technology",
    subMenu: [
      {
        name: "Documentation",
        desc: "Our Documentation",
        menuIcon: documentation,
        menuIconDark: documentationDark,
        path: "https://docs.iagon.com/",
      },
      {
        name: "Tools and technology",
        desc: "How Does It Work?",
        menuIcon: toolAndTechnology,
        menuIconDark: toolAndTechnologyDark,
        path: "https://iagon.com/storage",
      },
      {
        name: "LP Calculator",
        desc: "Liquidity Providers Reward Calculator",
        menuIcon: calculator,
        menuIconDark: calculatorDark,
        path: "https://calculator.iagon.com/",
      },
      {
        name: "V1/V2 IAG swap to CNT",
        desc: "Claim your CNT IAG Tokens",
        menuIcon: claim,
        menuIconDark: claimDark,
        path: "https://claim.iagon.com/",
      },
    ],
  },
  {
    menuName: "About us",
    path: "https://iagon.com/about-us",
    subMenu: [
      {
        name: "Our story",
        desc: "What is our mission?",
        menuIcon: story,
        menuIconDark: storyDark,
        path: "https://iagon.com/about-us#story",
      },
      {
        name: "Our team",
        desc: "Check who we are",
        menuIcon: team,
        menuIconDark: teamDark,
        path: "https://iagon.com/about-us#team",
      },
      {
        name: "Our partners",
        desc: "Who we work with",
        menuIcon: partner,
        menuIconDark: partnerDark,
        path: "https://iagon.com/careers#partners",
      },
      {
        name: "Contact us!",
        desc: "Check partnership opportunities.",
        menuIcon: contactUs,
        menuIconDark: contactUsDark,
        path: "",
      },
    ],
  },
  {
    menuName: "Join us",
    path: "https://iagon.com/join-us",
    subMenu: [
      {
        name: "Community",
        desc: "Join our community",
        menuIcon: team,
        menuIconDark: teamDark,
        path: "https://linktr.ee/iagon",
      },
      {
        name: "Join our team",
        desc: "Check job offers!",
        menuIcon: join,
        menuIconDark: joinDark,
        path: "https://iagon.com/careers",
      },
    ],
  },
];

export default Menu;
