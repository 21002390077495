import styled from "styled-components";
import { Card } from "../../components/Cards/Card";

export const DisplayCard = styled(Card)`
  width: 100%;
  align-items: start;
  background: ${(props) => props.theme.ispoCardBg};
  box-shadow: none;
  padding: 1.5rem;
  margin-bottom: 15px;
  @media (max-width: 1008px) {
    border-radius: 0;
    padding: 1.1rem 15px 15px 15px;
  }
`;

export const Title = styled.p`
  color: ${(props) => props.theme.addressInputPlaceholder};
  font-size: 24px;
  margin: 0;
`;

export const Label = styled.p`
  margin: -5px 0;
  word-break: break-word;
`;

export const TokenLabel = styled.p`
  margin: -5px 0;
  font-size: 16px;
  font-weight: ${(props) => (props.bold === 1 ? "bold" : "normal")};
  color: ${(props) => props.theme.fontColor};
`;

export const InfoCard = styled(Card)`
  background-color: #f6f8fc;
  align-items: start;
  width: 100%;
  background: ${(props) => props.theme.checkInfoCardBg};
  box-shadow: none;
  padding: 0px;
  @media (max-width: 640px) {
    border-radius: 8px !important;
  }
`;

export const FlexView = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
`;

export const TotalCard = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: ${(props) =>
    `0.5px solid ${props.theme.addressInputPlaceholder}`};
`;
