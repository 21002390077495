import React from 'react'
import { TabBarWrapper, Tab } from './styles.elements'

const TabBar = ({myWallet, otherAddress, tabPosition}) => {
  return (
    <TabBarWrapper>
        <Tab selected={tabPosition === 0} onClick={myWallet}>My Wallet</Tab>
        <Tab selected={tabPosition === 1} onClick={otherAddress}>Stake Key</Tab>
    </TabBarWrapper>
  )
}

export default TabBar