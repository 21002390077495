import { Link } from "../Link";
import { useTheme } from "styled-components";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Btn from "./btn";
import data from "../../data/menu";
import DropdownElement from "./dropdownElement";
import DarkLightBtn from "./darkLightBtn";
import join from "../../assets/menuIcons/become-a-member.svg";
import joinDark from "../../assets/menuIconsDark/become-a-member.svg";

export default function SiteMobileMenu({ handleMobileMenu, themeSwitch }) {
  const theme = useTheme();
  const headerItemTarget = ["White paper", "Community", "Contact us!"];
  const onClickGetStarted = () => {
    window.open("https://app.iagon.com/", "_blank");
  };

  return (
    <div
      className={`${
        theme.type === "dark" ? "bg-[#14121B]" : "bg-[#F3F7FC]"
      } h-full z-50 w-screen sm:w-1/2 fixed p-6 overflow-auto lg:hidden`}
    >
      <button
        aria-label="hamburger-menu"
        className="w-5 h-5 mb-3 text-left"
        onClick={handleMobileMenu}
      >
        <XMarkIcon />
      </button>
      <div className="mb-2">
        {data.map((el) => (
          <div key={el.menuName}>
            <Link
              href={
                el.menuName === "Join us" || el.menuName === "Technology"
                  ? ""
                  : el.path
              }
              onClick={handleMobileMenu}
            >
              <h1
                className={`p-2 text-base text-[#80859b] active:bg-white rounded-lg ${
                  theme.type === "dark" && "text-[#eee1ff] active:bg-[#2D2350]"
                }`}
              >
                {el.menuName}
              </h1>
            </Link>
            <ul>
              {el.subMenu.map((item) => (
                <Link
                  key={item.name}
                  href={item.path}
                  onClick={handleMobileMenu}
                  target={
                    headerItemTarget.includes(item.name) ? "newTab" : "_self"
                  }
                >
                  <DropdownElement
                    title={item.name}
                    subTitle=""
                    icon={item.menuIcon}
                    iconDark={item.menuIconDark}
                  />
                </Link>
              ))}
              {el.menuName === "Products" && (
                <Link
                  href="https://blog.iagon.com/iagon-trusted-tester-program-announced/"
                  target="newTab"
                >
                  <DropdownElement
                    title="Become a beta tester"
                    subTitle=""
                    icon={join}
                    iconDark={joinDark}
                  />
                </Link>
              )}
            </ul>
          </div>
        ))}

        <DarkLightBtn
          customStyle="p-2 flex items-center gap-2 w-full"
          mobileView
          themeSwitch={themeSwitch}
        />
      </div>
    </div>
  );
}
