import React, { useState } from "react";
import "./App.css";
import { Provider } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { store } from "./redux/store/store";
import { darkTheme, GlobalStyles, lightTheme } from "./theme";
import Footer from "./components/Footer";
import NavigationBar from "./components/Header";
import ToastProvider from "./components/ToastProvider";
import { ReactSession } from "react-client-session";
import { useAPIContext } from "./contexts/calculatorContext";
import { CircularProgress } from "@mui/material";
import { Redirect, Route, Switch } from "wouter";
import ChooseLspo from "./pages/ChooseLspo";
import Lspo2 from "./pages/Lspo2";
import Lspo1 from "./pages/Lspo1";

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Centered = styled.div`
  height: 75vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function App() {
  ReactSession.setStoreType("sessionStorage");
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const { loading, configError } = useAPIContext();

  const handleThemeChange = () => {
    localStorage.setItem("theme", theme === "light" ? "dark" : "light");
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <GlobalStyles />
        <ToastProvider position="top-right" />
        <Content>
          <NavigationBar
            themeSwitch={() =>
              handleThemeChange(theme === "light" ? "dark" : "light")
            }
          />

          <div
            className="w-full min-h-[70vh] pt-28 lg:pt-32 pb-10"
            style={{ contain: "paint" }}
          >
            <Switch>
              <Route path="/">
                <ChooseLspo />
              </Route>
              <Route path="/lspo1">
                <Lspo1 />
              </Route>
              <Route path="/lspo2">
                {loading ? (
                  <Centered>
                    <CircularProgress
                      sx={{ color: theme === "light" ? "#2EABF1" : "white" }}
                    />
                  </Centered>
                ) : configError ? (
                  <Centered>
                    <h3>Could not load config :(</h3>
                  </Centered>
                ) : (
                  <Lspo2 />
                )}
              </Route>
              <Redirect to="/" />
            </Switch>
          </div>

          <Footer />
        </Content>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
