import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

export const LpContainer = styled(Container)`
  padding: 0;
  @media (min-width: 1440px) {
    /* margin-left:300px; */
  }
`;
export const PageHeading = styled.h1`
  font-size: 26px;
  color: ${(props) => props.theme.fontColor};
  margin-top: 12px;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 640px) {
    text-align: center;
    font-size: 20px;
    margin-left: 15px;
    /* margin-left:20px; */
  }
`;
export const InputsContainer = styled(Container)`
  width: 100%;
  padding: 0;
  /* margin:0; */
  @media (max-width: 1008px) {
    /* padding: 5px; */
  }
`;
export const StyledRow = styled(Row)`
  padding: 0;
  display: flex;
  justify-content: center;
`;
export const DisplayRewardWrapper = styled.div`
  height: 180px;
  display: inline;
  @media (max-width: 1008px) {
    display: none;
  }
`;

export const DisplayRewardWrapperMobile = styled(Container)`
  display: none;
  @media (max-width: 1008px) {
    display: block;
    max-width: 100%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    width: 100%;
    margin: -4px 0 0 0;
    padding: 0;
    transition: 0.2s ease;
    box-shadow: ${(props) =>
      props.issticky === 1
        ? `0 -10px 20px -10px ${props.theme.displayBoxShadow}`
        : "none"};
  }
`;

export const GlobalSettingsWrapperMobile = styled.div`
  display: none;
  @media (max-width: 1008px) {
    display: block;
  }
`;

export const DisplayContainer = styled(Container)`
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: block;
  position: sticky;
  position: -webkit-sticky;
  top: 105px;
  width: 100%;
  max-width: 540px;
  /* margin-top: 67.5px; */

  @media (max-width: 1008px) {
    max-width: 592px;
    margin-top: -4px;
  }
  @media (max-width: 1008px) {
    display: none;
  }
`;



export const StakeGlobalColumn = styled(Col)`
  max-width: 540px;
  padding: 0px;
  width: 100%;
  @media (max-width: 1008px) {
    max-width: 592px;
    margin-bottom: 1rem;
  }
`;
export const DisplayColumn = styled(Col)`
  /* padding: 0; */
  max-width: 450px;
  @media (max-width: 1439px) {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  @media (max-width: 1008px) {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 592px;
  }
`;

export const ShadowIndicator = styled.div`
  height: 100px;
  width: 100px;
  position: absolute;
`;
