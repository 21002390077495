import styled from "styled-components";

export const Link = (props) => {
  const { href, newTab, children } = props;

  return (
    <StyledLink
      href={href}
      passHref
      target={newTab ? "_blank" : "undefined"}
      rel={newTab ? "noopener" : undefined}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export const StyledLink = styled.a`
  color: ${(props) =>
    props.disabled ? props.theme.textBright : props.theme.fontColor};
  text-decoration: none;
  pointer-events: ${(props) => (props.disabled ? "none" : "")};
  cursor: ${(props) => (props.disabled ? "default" : "")};

  &:hover {
    color: ${(props) => props.theme.textBright};
  }
`;
