import React, { createRef, useState } from "react";
import checkmark from "../../assets/icons/checkmark_circle.svg";
import { Button, FormWrapper, IconWrapper, Input } from "./styles.elements";

export default function Index(props) {
  const [formValid, setFormValid] = useState(true);
  const {
    value,
    onChange,
    validation,
    valid,
    placeholder,
    type,
    dataType,
    children,
    paste,
    min,
    max,
    errorMessage,
    setErrorMessage,
    disabled = false
  } = props;
  const inputRef = createRef();
  const pasteFromClipboard = async (event) => {
    event.preventDefault();
    const clipboardText = await navigator.clipboard.readText();
    inputRef.current.value = clipboardText;
  };
  const fieldValidation = (value, min, max, dataType) => {
    if (dataType === "integer") {
      if ((value + "").includes(".")) {
        setErrorMessage("Value must be an integer ");
        setFormValid(false);
        return false;
      } else {
        setErrorMessage(null);
        setFormValid(true);
      }
    } 
      value = parseFloat(value.toString().replace(",",""));
      if (value < min) {
        setErrorMessage("Value must be larger than " + min);
        setFormValid(false);
        return false;
      } else if (value > max) {
        setErrorMessage("Value can’t be larger than " + max);
        setFormValid(false);
        return false;
      } else if (value >= min && value <= max) {
        setErrorMessage(null);
        setFormValid(true);
        return true;
      }
    
  };
  const handleChange = (event) => {
    fieldValidation(event.target.value, min, max, dataType);
    onChange(event);
  };

  return (
    <FormWrapper
      value={value}
      {...props}
      style={{  border: errorMessage!==null&&errorMessage!=="" ? "1px solid red": null}}
    >
      <Input
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
        ref={inputRef}
        type={type}
        disabled={disabled}
      />
      {validation && valid ? (
        <IconWrapper>
          <img
            src={checkmark}
            alt="valid"
            width="20rem"
            height="20rem"
            className="rounded-top rounded-bottom"
          />
        </IconWrapper>
      ) : paste ? (
        <Button onClick={pasteFromClipboard}>Paste</Button>
      ) : null}
      {children ? children : null}
    </FormWrapper>
  );
}
