import React, { useState, useEffect } from "react";
import { Bars3Icon } from "@heroicons/react/24/solid";
import DarkLightBtn from "./darkLightBtn";
import "../Footer/styles.css";
import Btn from "./btn";
import SiteMobileMenu from "./siteMobileMenu";
import SearchMenu from "./searchMenu";
import PrimaryMenu from "./primaryMenu";
import { useTheme } from "styled-components";

export default function SiteNavbar({ themeSwitch }) {
  const theme = useTheme();
  const [expand, setExpand] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [scroll, setScroll] = useState(false);

  const handleSearch = () => {
    setExpand(!expand);
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
    setExpand(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  const onClickGetStarted = () => {
    window.open("https://app.iagon.com/", "_self");
  };

  return (
    <section
      className={`${
        theme.type === "dark" ? "nav-dark" : "light"
      } flex justify-center inset-x-0 fixed z-10`}
    >
      <div className="container">
        <nav className="flex justify-between items-center h-20 lg:h-24 w-full">
          <div className="basis-[56%] md:basis-[55%] xl:basis-3/5 2xl:basis-1/2 flex items-center justify-between gap-2 md:gap-0">
            <button
              aria-label="mobile-menu"
              className="w-9 h-9 lg:hidden"
              onClick={handleMobileMenu}
            >
              <Bars3Icon />
            </button>
            <div
              className={`absolute left-0 top-0 ${mobileMenu ? "" : "hidden"}`}
            >
              <SiteMobileMenu
                themeSwitch={themeSwitch}
                handleMobileMenu={handleMobileMenu}
              />
            </div>
            <PrimaryMenu expand={expand} />
          </div>
          <div className="flex items-center gap-3">
            <div className="">
              <SearchMenu expand={expand} handleSearch={handleSearch} />
            </div>

            <div className="hidden md:block">
              <DarkLightBtn
                customStyle={`bg-white shadow-2xl
                ${theme.type === "dark" && "bg-[#1B1926]"}`}
                mobileView={false}
                themeSwitch={themeSwitch}
                // theme={theme}
              />
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
}
