import React from "react";
import { useTheme } from "styled-components";

export default function ComingSoonBadge({ title }) {
  const theme = useTheme();
  return (
    <button
      aria-label="coming-soon"
      className={`${
        theme.type === "dark"
          ? "bg-[#E0DEFB] text-[#2D2350]"
          : "bg-[#7441B5] text-[#E0DEFB]"
      } px-2.5 py-0.5 ml-2 rounded-xl text-xs font-medium  animate__animated animate__fadeIn`}
    >
      {title}
    </button>
  );
}
