import React, { useEffect } from "react";
import ReactModal from "react-modal";

export default function HubSpotForm({ openModal, onCloseModal }) {
  const customStyles = {
    content: {
      overflow: "auto",
      zIndex: 10000,
    },
    overlay: {
      zIndex: 9999,
    },
  };

  useEffect(() => {
    if (openModal) {
      const script = document.createElement("script");
      script.src = "//js-eu1.hsforms.net/forms/v2.js";
      script.charset = "utf-8";
      script.type = "text/javascript";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        const windowWithHbspt = window;
        if (windowWithHbspt.hbspt) {
          windowWithHbspt.hbspt.forms.create({
            portalId: "25222450",
            formId: "137fb4fc-1a2b-4cf3-9a5a-3c972ed5363c",
            region: "eu1",
            target: "#hubspotForm",
          });
        }
      });
    }
  }, [openModal]);

  return (
    <button aria-label="modal-button" onClick={onCloseModal}>
      <ReactModal
        isOpen={openModal}
        onRequestClose={onCloseModal}
        ariaHideApp={false}
        style={customStyles}
        className="modal-container fixed inset-0 bg-gray-500 bg-opacity-75 z-50 flex justify-center"
      >
        <div
          id="hubspotForm"
          className="bg-white flex items-center my-20 p-6 rounded-lg w-5/6 lg:w-[768px] h-[70rem] sm:h-[65rem] md:h-[880px]"
        />
      </ReactModal>
    </button>
  );
}
