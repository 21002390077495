import { Lucid } from "lucid-cardano";

export const initWallet = async (walletName) => {
  if (!window.cardano) {
    return {
      response: undefined,
      message: `Please install a Cardano Wallet (Nami | Eternl | Flint)`,
    };
  }
  try {
    // Request account access if needed
    const wallet = await window.cardano[walletName].enable();
    return {
      response: wallet,
      message: `${walletName} connected successfully`,
    };
  } catch (error) {
    console.log("wallet error", error);
    return {
      response: false,
      message: `You need to allow ${walletName} to login`,
    };
  }
};

export const getAddress = async (walletName) => {
  const cWallet = await initWallet(walletName);
  try {
    let cardanoAddr = undefined;
    if (cWallet.response) {
      const lucid = await Lucid.new(undefined, "Mainnet");
      lucid.selectWallet(cWallet.response);
      cardanoAddr = await lucid.wallet.rewardAddress();
      if (!cardanoAddr)
        return { _error: true, _address: undefined, _wallet: cWallet };
      return { _error: false, _address: cardanoAddr, _wallet: cWallet };
    } else {
      return { _error: true, _address: undefined, _wallet: cWallet };
    }
  } catch (e) {
    console.log(e);
    return {
      _error: true,
      _address: undefined,
      _wallet: {
        response: undefined,
        message: "Could not get address from wallet",
      },
    };
  }
};
