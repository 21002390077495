const FooterItem = [
  {
    title: "Products",
    path: "/",
    subItem: [
      {
        path: "https://www.iagon.com/",
        item: "Home",
      },
      {
        path: "https://www.iagon.com/storage",
        item: "Storage",
      },
      {
        path: "",
        item: "Agora",
      },
      {
        path: "https://app.iagon.com/ledgerflow",
        item: "LedgerFlow",
      },
      {
        path: "https://blog.iagon.com/iagon-trusted-tester-program-announced/",
        item: "Become a beta tester",
      },
    ],
  },
  {
    title: "Technology",
    path: "#",
    subItem: [
      {
        path: "",
        item: "Resource providers",
      },
      {
        path: "",
        item: "Tools and technology",
      },
      {
        path: "https://calculator.iagon.com/",
        item: "LP Calculator",
      },
      {
        path: "https://claim.iagon.com/",
        item: "V1/V2 IAG swap to CNT",
      },
      {
        path: "https://docs.iagon.com/",
        item: "Documentation",
      },
      {
        path: "",
        item: "Provide storage",
      },
    ],
  },
  {
    title: "Company",
    path: "#",
    subItem: [
      {
        path: "https://www.iagon.com/about-us",
        item: "About us",
      },
      {
        path: "https://www.iagon.com/careers",
        item: "Careers",
      },
      {
        path: "https://blog.iagon.com/",
        item: "Blog",
      },
      {
        path: "https://www.iagon.com/about-us#team",
        item: "Our team",
      },
      {
        path: "https://www.iagon.com/careers#partners",
        item: "Partners",
      },
      {
        path: "",
        item: "Contact us",
      },
    ],
  },
  {
    title: "Legal",
    path: "#",
    subItem: [
      {
        path: "https://www.iagon.com/privacy-policy",
        item: "Privacy policy",
      },
      {
        path: "https://www.iagon.com/aml-cft-policy",
        item: "AML/CFT policy",
      },
      {
        path: "https://www.iagon.com/terms-of-use",
        item: "Terms of use",
      },
    ],
  },
];

export default FooterItem;
