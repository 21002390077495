import React, { useState } from "react";
import {
  RewardHistoryCard,
  TitleWrapper,
  TextButton,
  Header,
  Value,
  Reward,
  RewardHeader,
  ExpandMore,
  ExpandedDetail,
  ValueExp,
  LabelInfo,
  OnlyMobile,
} from "./styles.element";
import { useTheme } from "styled-components";
import expandMore from "../../assets/icons/expand-more.svg";
import arrowDown from "../../assets/icons/arrow-down.svg";

const RewardHistory = ({ rewards }) => {
  const theme = useTheme();
  const [seeMore, setSeeMore] = useState(false);

  const getRewards = () => {
    return seeMore ? rewards.toReversed() : rewards.toReversed().slice(0, 5);
  };

  return (
    <RewardHistoryCard>
      <TitleWrapper>
        <h3>Reward History</h3>
        <TextButton
          onClick={() => setSeeMore(!seeMore)}
          style={{ color: theme.editSettingBtn, fontSize: "14px" }}
        >
          {seeMore ? "See Less" : "See More"}
        </TextButton>
      </TitleWrapper>
      <Header>
        <LabelInfo>Epoch</LabelInfo>
        <LabelInfo style={{ fontWeight: "bold" }}>IAG + [Bonus] Reward</LabelInfo>
        <LabelInfo style={{ fontWeight: "bold" }}>
          Dollar + [Bonus] Reward
        </LabelInfo>
        <LabelInfo style={{ fontWeight: "bold" }}>Epoch APY</LabelInfo>
        <LabelInfo hideOnMobile={1}>ADA Delegated</LabelInfo>
        <LabelInfo hideOnMobile={1}>IAG/ADA Staked</LabelInfo>
        <div />
      </Header>
      {getRewards().map((reward) => (
        <RewardDetail key={reward.epoch} reward={reward} />
      ))}
    </RewardHistoryCard>
  );
};

const RewardDetail = ({ reward }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  return (
    <Reward>
      <RewardHeader expanded={expanded ? 1 : 0}>
        <Value>{reward.epoch}</Value>
        <Value>
          {reward.baseReward.toLocaleString()} + [
          {reward.bonusReward.toLocaleString()}] IAG
        </Value>
        <Value>
          {(reward.baseReward * reward.iagPrice).toFixed(2)} + [
          {(reward.bonusReward * reward.iagPrice).toFixed(2)}] USD
        </Value>
        <Value>{reward.apyWithBonus.toFixed(2)}%</Value>
        <Value hideOnMobile={1}>{reward.delegated.toLocaleString()} ADA</Value>
        <Value hideOnMobile={1}>{reward.liquidity.toLocaleString()} USD</Value>
        <ExpandMore>
          <img
            style={{
              height: theme.type === "dark" ? "15px" : "20px",
              cursor: "pointer",
              transform: `rotate(${expanded ? "180" : "0"}deg)`,
              marginRight: "10px",
            }}
            alt="expand"
            src={theme.type === "dark" ? arrowDown : expandMore}
            onClick={() => setExpanded(!expanded)}
          />
        </ExpandMore>
      </RewardHeader>
      {expanded && (
        <ExpandedDetail>
          <div>
            <LabelInfo>ADA Rate</LabelInfo>
            <ValueExp>${reward.adaPrice.toFixed(4)}</ValueExp>
          </div>
          <div>
            <LabelInfo>IAG Rate</LabelInfo>
            <ValueExp>${reward.iagPrice.toFixed(4)}</ValueExp>
          </div>
          <div>
            <LabelInfo>Total ADA Delegated</LabelInfo>
            <ValueExp>{reward.totalDelegated.toLocaleString()} ADA</ValueExp>
          </div>
          <div>
            <LabelInfo>Total IAG/ADA Staked</LabelInfo>
            <ValueExp>
              {(reward.totalLiquidity * reward.adaPrice).toLocaleString()} USD
            </ValueExp>
          </div>
          {/* display on mobile view */}
          <OnlyMobile>
            <LabelInfo>ADA Rate</LabelInfo>
            <ValueExp>{reward.adaPrice.toFixed(4)}</ValueExp>
          </OnlyMobile>
          <OnlyMobile>
            <LabelInfo>IAG Rate</LabelInfo>
            <ValueExp>{reward.iagPrice.toFixed(4)}</ValueExp>
          </OnlyMobile>
        </ExpandedDetail>
      )}
    </Reward>
  );
};

export default RewardHistory;
