import { useState } from "react";
import { Link } from "../Link";
import { useTheme } from "styled-components";
import DropdownElement from "./dropdownElement";
import BecomeBetaTest from "./becomeBetaTest";
import data from "../../data/menu.js";
import NavbarLogo from "./navbarLogo";
import DropdownAnimation from "./dropdownAnimation";
import HubSpotForm from "../hubSpotForm";

export default function PrimaryMenu({ expand }) {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);

  const headerItemDisabled = [
    "Agora",
    "Provide storage",
    "Tools and technology",
  ];

  const handleContactUs = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <DropdownAnimation />
      <div className="hidden lg:flex">
        <NavbarLogo />
      </div>
      <nav className="font-karlaRegular top flex justify-between">
        <div
          className={`${
            expand
              ? "scale-0 sm:block duration-300"
              : "block scale-100 delay-300 duration-500"
          } lg:hidden`}
        >
          <NavbarLogo />
        </div>
        <div
          className={`${
            theme.type === "dark" ? "bg-[#100F18]" : "bg-white"
          } dropdownBackground`}
        >
          <span
            className={`${
              theme.type === "dark" ? "bg-[#100F18]" : "bg-white"
            } arrow`}
          />
        </div>
        <ul className="hidden lg:flex menu lg:gap-5 xl:gap-10">
          {data.map((el) => (
            <li className="parent" key={el.menuName}>
              <Link
                href={
                  el.menuName === "Join us" || el.menuName === "Technology"
                    ? ""
                    : el.path
                }
                onClick={(e) =>
                  (el.menuName === "Join us" || el.menuName === "Technology") &&
                  e.preventDefault()
                }
              >
                <p
                  className={`py-5 text-base font-karlaRegular opacity-100 hover:opacity-75 ${
                    theme.type === "dark" ? "text-[#eee1ff]" : "text-[#80859b]"
                  }`}
                >
                  {el.menuName}
                </p>
              </Link>
              <div
                className={`child ${
                  theme.type === "dark" ? "bg-[#100F18]" : "bg-white"
                }`}
              >
                <ul className="py-3 px-3">
                  {el.subMenu.map((item) => (
                    <li
                      key={item.name}
                      className={`group  rounded-lg ${
                        theme.type === "dark"
                          ? "hover:bg-gray-500/50"
                          : "hover:bg-gray-100"
                      }`}
                    >
                      <Link
                        href={
                          item.name === "Agora" ||
                          item.name === "Provide storage" ||
                          item.name === "Tools and technology"
                            ? ""
                            : item.path
                        }
                        target={
                          item.name === "Documentation" ||
                          item.name === "Community"
                            ? "newTab"
                            : "_self"
                        }
                        onClick={(e) => {
                          if (headerItemDisabled.includes(item.name)) {
                            e.preventDefault();
                          } else if (item.name === "Contact us!") {
                            handleContactUs();
                            e.preventDefault();
                          }
                        }}
                        className={item.name}
                      >
                        <DropdownElement
                          title={item.name}
                          subTitle={item.desc}
                          icon={item.menuIcon}
                          iconDark={item.menuIconDark}
                        />
                      </Link>
                    </li>
                  ))}
                </ul>
                {el.menuName === "Products" && <BecomeBetaTest />}
              </div>
            </li>
          ))}
        </ul>
        <HubSpotForm openModal={openModal} onCloseModal={onCloseModal} />
      </nav>
    </>
  );
}
