import React from "react";
import { Link } from "../Link";
import SVG from "./svg";
import logo from "../../assets/Logos/iagon-logo.svg";
import logoBlk from "../../assets/Logos/iagon-logo-blk.svg";
import logoVertical from "../../assets/Logos/iagon-logo-vertical.svg";
import logoVerticalBlk from "../../assets/Logos/iagon-logo-vertical-blk.svg";
import { useTheme } from "styled-components";

export default function NavbarLogo() {
  const theme = useTheme();
  return (
    <Link href="https://iagon.com/" target="" aria-label="iagon-logo">
      <div className="w-full hidden sm:block lg:hidden xl:block xl:ml-10">
        <SVG img={theme.type === "dark" ? logo : logoBlk} />
      </div>
      <div className="w-full sm:hidden lg:block xl:hidden">
        <SVG img={theme.type === "dark" ? logoVertical : logoVerticalBlk} />
      </div>
    </Link>
  );
}
