import React from "react";
import { useTheme } from "styled-components";
import ComingSoonBadge from "./comingSoonBadge";
import SVG from "./svg";

export default function DropdownElement({ title, subTitle, icon, iconDark }) {
  const theme = useTheme();
  const titleDisabled = ["Agora", "Tools and technology", "Provide storage"];
  return (
    <div
      className={`${
        theme.type === "dark" && "active:bg-[#2D2350] active:lg:bg-[#100F18]"
      } flex items-center gap-4 p-2 rounded-lg`}
    >
      <span className="w-8 h-8 grid place-items-center rounded-lg">
        <SVG img={theme.type === "dark" ? iconDark : icon} />
      </span>
      <span>
        <span className="flex flex-wrap">
          <h1 className="text-base inline-block">{title}</h1>
          {title === "Agora" ||
            (title === "Tools and technology" && (
              <ComingSoonBadge title="Coming Soon!" />
            ))}
        </span>
        {subTitle && (
          <p
            className={`
              ${title === "Tools and technology" && "lg:w-72"}
              ${title === "Contact us" && "lg:w-52"}
              ${theme.type === "dark" ? "text-[#b5a9df]" : "text-[#80859b]"}
              w-fit whitespace-nowrap lg:min-w-[10rem]
			  text-xs opacity-70
			  ${
          titleDisabled.includes(title)
            ? "group-hover:opacity-70"
            : "group-hover:opacity-100"
        } 
            `}
          >
            {subTitle}
          </p>
        )}
      </span>
    </div>
  );
}
