export const setUserWallet=(payload)=>{
  return{
    type: "SET_WALLET",
    payload:payload
  }
}

export const setGlobalSettings = (payload) => {
  return {
    type: "SET_GLOBAL_SETTINGS",
    payload: payload,
  };
};

export const resetGlobalSettings = () => {
  return {
    type: "RESET_GLOBAL_SETTINGS",
  };
};

export const setGlobalRewarded = (payload) => {
  return {
    type: "SET_GLOBAL_REWARDED",
    payload: payload,
  };
};

export const setGlobalDelegated = (payload) => {
  return {
    type: "SET_GLOBAL_DELEGATED",
    payload: payload,
  };
};
