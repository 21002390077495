import React, { useState } from "react";
import { useTheme } from "styled-components";
import { Link } from "../Link";
import { FaTwitter, FaLinkedinIn, FaYoutube, FaDiscord } from "react-icons/fa";
import { TbBrandTelegram } from "react-icons/tb";
import { TfiFacebook } from "react-icons/tfi";
import FooterItem from "../../data/footer";
import HubSpotForm from "../hubSpotForm";
import "./styles.css";
import no from "../../assets/Logos/no.svg";
import logoVertical from "../../assets/Logos/iagon-logo-vertical.svg";
import logoVerticalBlk from "../../assets/Logos/iagon-logo-vertical-blk.svg";

export default function Footer() {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const footerItemDisabled = [
    "Tools and technology",
    "Agora",
    "Resource providers",
    "Provide storage",
  ];

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <section
      className={`${
        theme.type === "dark"
          ? "bg-footerMvDark sm:bg-footerDark"
          : "bg-footerMvLight sm:bg-footerLight"
      }   bg-no-repeat bg-contain bg-right-bottom sm:bg-bottom pt-8`}
    >
      <div className={"container flex justify-center"}>
        <div className="w-full md:w-11/12 lg:w-5/6 flex flex-col lg:flex-row justify-between mb-24 md:mb-14 lg:mb-0">
          <div className="flex flex-col gap-6">
            <div className="flex sm:flex-col gap-8 sm:gap-4">
              <img
                priority
                src={theme.type === "dark" ? logoVertical : logoVerticalBlk}
                alt="Iagon-logo"
                width={59}
                height={54}
              />
              <div
                className={`${
                  theme.type === "dark" ? "text-[#9F9BDD]" : "text-[#80859B]"
                } font-karlaRegular text-sm`}
              >
                <p>Iagon</p>
                <p>Worldwide</p>
              </div>
            </div>
            <div className="w-full flex gap-6 pr-6 md:gap-10 lg:gap-3 xl:gap-8">
              <Link href="https://twitter.com/IagonOfficial" target="newTab">
                <FaTwitter
                  aria-label="twitter-icon"
                  className="w-6 h-5 hover:scale-110 transition duration-300 hover:text-[#00acee]"
                />
              </Link>
              <Link
                href="https://www.linkedin.com/company/iagon/"
                target="newTab"
              >
                <FaLinkedinIn
                  aria-label="linkedin-icon"
                  className="w-6 h-5 hover:scale-110 transition duration-300 hover:text-[#0077b5]"
                />
              </Link>
              <Link
                href="https://www.facebook.com/IagonOfficial/"
                target="newTab"
              >
                <TfiFacebook
                  aria-label="facebook-icon"
                  className="w-6 h-5 hover:scale-110 transition duration-300 hover:text-[#3b5998]"
                />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCpIUqQSMK5cE4QncanEUpKg"
                target="newTab"
              >
                <FaYoutube
                  aria-label="youtube-icon"
                  className="w-6 h-5 hover:scale-110 transition duration-300 hover:text-[#ff0000]"
                />
              </Link>
              <Link href="https://t.me/Iagon_official" target="newTab">
                <TbBrandTelegram
                  aria-label="telegram-icon"
                  className="w-6 h-5 hover:scale-110 transition duration-300 hover:text-[#0088cc]"
                />
              </Link>
              <Link href="https://discord.gg/TCS23VWuWm" target="newTab">
                <FaDiscord
                  aria-label="discord-icon"
                  className="w-6 h-5 hover:scale-110 transition duration-300 hover:text-[#7289da]"
                />
              </Link>
            </div>
            <Link href="https://www.iagon.com/privacy-policy" target="newTab">
              <div
                className={`${
                  theme.type === "dark"
                    ? "text-[#F2F2FF]  hover:text-[#6dc6f2]"
                    : "hover:text-[#3b5998] text-[#80859B]"
                } flex gap-4 group font-karlaLight mb-5
            transition duration-300`}
              >
                <div className="w-6 h-6 relative">
                  <img
                    className="w-6 h-6 isolate absolute group-hover:scale-105 transition duration-200"
                    src={no}
                    alt="cookie"
                    width={33}
                    height={34}
                  />
                  <p className="w-6 h-6 text-center group-hover:scale-105 transition duration-300">
                    🍪
                  </p>
                </div>
                <p className="text-karlaRegular">No cookie policy</p>
              </div>
            </Link>
          </div>

          <div className="grid md:grid-cols-2 gap-y-5 lg:grid-cols-4 lg:gap-3 xl:gap-10 2xl:gap-24 mb-10 lg:mb-0 md:mr-56 lg:mr-0">
            {FooterItem.map((el, idx) => (
              //    motion.div
              <div className="font-karlaRegular">
                <p
                  className={`${
                    theme.type === "dark" ? "text-[#9F9BDD]" : "text-[#80859b]"
                  } mb-2`}
                >
                  {el.title}
                </p>
                {el.subItem.map((s) => (
                  <ul key={s.item}>
                    <Link
                      href={s.path}
                      target={s.item === "LP Calculator" ? "" : "newTab"}
                      onClick={(e) => {
                        if (footerItemDisabled.includes(s.item)) {
                          e.preventDefault();
                        } else if (s.item === "Contact us") {
                          setOpenModal(true);
                          e.preventDefault();
                        }
                      }}
                    >
                      <li
                        className={
                          footerItemDisabled.includes(s.item)
                            ? "text-[#9ca3af] disabled cursor-default"
                            : `{ ${
                                theme.type === "dark"
                                  ? "hover:text-[#9f9bdd]"
                                  : "hover:text-[#3C096C]"
                              }`
                        }
                      >
                        {s.item}
                      </li>
                    </Link>
                  </ul>
                ))}
                {/* </motion.div> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <HubSpotForm openModal={openModal} onCloseModal={onCloseModal} />
    </section>
  );
}
