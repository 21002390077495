import React from "react";
import {
  AmountView,
  DelegateWrapper,
  GlobalCard,
  GlobalWrapper,
  Header,
  StakeWrapper,
  Title,
} from "./styles.element";
import { LabelInfo } from "../DisplayRewards/styles.elements";
import { formatNumbersEur } from "../../helper";
import { useAPIContext } from "../../contexts/calculatorContext";

function GlobalSettings(props) {
  const { nvlPrice, saturnPrice, tvl, tvlIagUsdm, totalDelegated } =
    useAPIContext();
  return (
    <GlobalCard>
      <Header>
        <Title>Global Settings</Title>
      </Header>
      <GlobalWrapper>
        <StakeWrapper>
          <LabelInfo>Estimated IAG Reward</LabelInfo>
          <AmountView>
            {formatNumbersEur(props.estimatedIagReward?.toFixed(0))} IAG
          </AmountView>
        </StakeWrapper>
        {/* <StakeWrapper>
          <LabelInfo>Estimated IAG Reward from Nuvola</LabelInfo>
          <AmountView>
            {formatNumbersEur(props.estimatedIagRewardFromNuvola?.toFixed(0))} IAG
          </AmountView>
        </StakeWrapper> */}
        <StakeWrapper>
          <LabelInfo>NVL rewarded in total</LabelInfo>
          <AmountView>{formatNumbersEur("420000")} NVL</AmountView>
        </StakeWrapper>
        <StakeWrapper>
          <LabelInfo>Current NVL Price</LabelInfo>
          <AmountView>{nvlPrice.toFixed(5)} USD</AmountView>
        </StakeWrapper>
        <StakeWrapper>
          <LabelInfo>SATURN rewarded in total</LabelInfo>
          <AmountView>{formatNumbersEur("1000000")} SATURN</AmountView>
        </StakeWrapper>
        <StakeWrapper>
          <LabelInfo>Current Saturn Price</LabelInfo>
          <AmountView>{saturnPrice.toFixed(5)} USD</AmountView>
        </StakeWrapper>
        <DelegateWrapper>
          <LabelInfo>ADA delegated in total (IAGL1 & IAGL2)</LabelInfo>
          <AmountView>
            {formatNumbersEur(totalDelegated.toFixed(0))} ADA
          </AmountView>
        </DelegateWrapper>
        <StakeWrapper>
          <LabelInfo>Total liquidity provided (IAG/ADA)</LabelInfo>
          <AmountView>
            {formatNumbersEur(tvl.toFixed(0))}
            &nbsp;USD
          </AmountView>
        </StakeWrapper>{" "}
        <StakeWrapper>
          <LabelInfo>Total liquidity provided (IAG/USDM)</LabelInfo>
          <AmountView>
            {formatNumbersEur(tvlIagUsdm.toFixed(0))}
            &nbsp;USD
          </AmountView>
        </StakeWrapper>
      </GlobalWrapper>
    </GlobalCard>
  );
}

export { GlobalSettings };
