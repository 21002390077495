import React from "react";
import { useTheme } from "styled-components";
import {
  DisplayCard,
  Label,
  InfoCard,
  TokenLabel,
  FlexView,
  TotalCard,
} from "./styles.element";
import { formatNumbersEur } from "../../helper";

function RewardSummary({ summary }) {
  const theme = useTheme();
  return (
    <DisplayCard borderRadius="8px">
      <Label
        style={{
          fontSize: "16px",
          color: theme.addressInputPlaceholder,
          marginBottom: "5px",
        }}
      >
        Reward Summary
      </Label>
      <InfoCard borderRadius="8px">
        <FlexView>
          <TokenLabel>Base Reward</TokenLabel>
          <TokenLabel bold={1}>
            {" "}
            {formatNumbersEur(summary.totalBaseReward.iag.toFixed(6))} IAG
          </TokenLabel>
        </FlexView>
        <FlexView>
          <TokenLabel>Bonus Reward</TokenLabel>
          <TokenLabel bold={1}>
            {" "}
            {formatNumbersEur(summary.totalBonusReward.iag.toFixed(6))} IAG
          </TokenLabel>
        </FlexView>
        <TotalCard>
          <TokenLabel>Total Reward</TokenLabel>
          <TokenLabel bold={1}>
            {" "}
            {formatNumbersEur(
              (
                summary.totalBonusReward.iag + summary.totalBaseReward.iag
              ).toFixed(6)
            )}{" "}
            IAG
          </TokenLabel>
        </TotalCard>
      </InfoCard>
      <InfoCard borderRadius="8px" style={{ marginTop: "12px" }}>
        <FlexView>
          <TokenLabel>Total APY</TokenLabel>
          <TokenLabel bold={1}> {summary.apyWithBonus?.toFixed(2) || 0}%</TokenLabel>
        </FlexView>
      </InfoCard>
    </DisplayCard>
  );
}

export default RewardSummary;
